import React, {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate} from 'react-router-dom'
import { deleteYield, getYield, updateYield } from 'features/yield/yieldSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {Status} from "../../../types/slice";
import {YieldModel} from "@asirisos/types";
import ChangeActivityList from '../Activity/ChangeActivityList'
import DeleteModal from 'UI/components/DeleteModal'
import {InputSelection} from "../../../types/ui";
import Modal from 'react-modal'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'

function YieldPage() {
    
    const {t} = useTranslation("yield")
    const navigate = useNavigate()
    const {yieldId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {singleYield, status} = useSelector((state: RootState) => state.yield)
    const {allActivity} = useSelector((state: RootState) => state.activity)

    const categorySelection = [
        new InputSelection(t("yield:categoryFeed"), 'feed'),
        new InputSelection(t("yield:categoryChemicals"), 'chemicals'),
        new InputSelection(t("yield:categoryFertilizerAndConditioners"), 'fertilizerAndConditioners'),
        new InputSelection(t("yield:categoryFuel"), 'fuel'),
        new InputSelection(t("yield:categoryFarmSupplies"), 'farmSupplies'),
        new InputSelection(t("yield:categoryEquipmentParts"), 'equipmentParts'),
        new InputSelection(t("yield:categorySeedsAndPlants"), 'seedsAndPlants'),
        new InputSelection(t("yield:categoryEquipmentMaintenance"), 'equipmentMaintenance'),
        new InputSelection(t("yield:categoryInfrastructureMaintenance"), 'infrastructureMaintenance'),
        new InputSelection(t("yield:categoryLivestockMaintenance"), 'livestockMaintenance'),
        new InputSelection(t("yield:categoryOther"), 'other'),]

    useEffect(() => {
        if (yieldId) {
            dispatch(getYield(yieldId))
            dispatch(getActivityByConnectionId(yieldId!)) }
    }, [dispatch, yieldId])

    if (status === Status.Failure) {
        toast.error(t("yield:yieldGetFailed"))
    }

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        totalArea: '',
        totalAreaUnit: '',
        connectionCategory: '',
        yieldStageGrowing: 'false',
        yieldStageStorage: 'false',
        crop: '',
        liveStock: '',
        farmField: '',
        infrastructure: '',
        storage: '',
    })

    const {title, totalArea, totalAreaUnit, description, connectionCategory, crop, liveStock, yieldStageGrowing, yieldStageStorage, farmField, infrastructure,
        storage} = formData


    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData
    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && singleYield !== undefined)
            dispatch(getActivityByConnectionId(yieldId!))
    }, [yieldId, status])

    useEffect(() => {
        if (status !== Status.Pending && singleYield !== undefined) setFormData((prevState) => ({
            ...prevState,
            title: singleYield.title, 
            totalArea: singleYield.totalArea, 
            totalAreaUnit: singleYield.totalAreaUnit, 
            description: singleYield.description, 
            connectionCategory: singleYield.connectionCategory, 
            // crop: singleYield.crop, 
            // liveStock: singleYield.liveStock, 
            yieldStageGrowing: singleYield.yieldStageGrowing, 
            yieldStageStorage: singleYield.yieldStageStorage, 
            // farmField: singleYield.farmField, 
            // infrastructure: singleYield.infrastructure,
            // storage: singleYield.storage
        }));
    }, [status])

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        changedField = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const yieldData: YieldModel = {
            _id: yieldId,
            totalArea,
            totalAreaUnit,
            connectionCategory,
            yieldStageGrowing,
            yieldStageStorage,
            farmField,
            title,
            infrastructure,
            description,
            crop,
            liveStock,
            storage,

            
            // type: e.target.value, 
            // changedField: e.target.id
        }

        dispatch(updateYield(yieldData))
            .unwrap()
            .then(() => {
                toast.success(t("yield:yieldUpdated"))
            })
            .catch(() =>{
                toast.error(t("yield:yieldUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        const yieldData: YieldModel = {
            totalArea,
            totalAreaUnit,
            connectionCategory,
            yieldStageGrowing,
            yieldStageStorage,
            farmField,
            title,
            infrastructure,
            description,
            crop,
            liveStock,
            storage,
            _id: yieldId,
            changedField: changedData.field
        }

        dispatch(updateYield(yieldData))
            .unwrap()
            .then(() => {
                toast.success(t("yield:yieldUpdated"))
            })
            .catch(() =>{
                toast.error(t("yield:yieldUpdateFailed"))
            })
    }


    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////

    const onDelete = () => {
        dispatch(deleteYield([yieldId!]))
        .unwrap()
        .then(() => {
            toast.success(t("yield:yieldDeleted"));
            navigate("/yield")
        })
        .catch(() => {
            toast.error(t("yield:failedToDeleteyield"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


    if (status === Status.Pending && singleYield?.title != '' && singleYield?.connectionCategory != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div
                    className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:title")} value={title} id={"title"}
                                        name={"title"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:description")} value={description}
                                        id={"description"} name={"description"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:totalArea")} value={totalArea.toString()}
                                        id={"totalArea"} name={"totalArea"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("yield:value")} value={description} id={"description"}
                                        name={"description"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)} 
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={connectionCategory} id={"connectionCategory"} title={t("translation:connectionCategory")} 
                                                name={t("translation:connectionCategory")}/>
                        </div>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                    <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={singleYield?.title!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Yield'} />
                
            </>
        )
    }
}


export default YieldPage