import React, {useEffect, useState, KeyboardEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link} from 'react-router-dom'
import {getActivity, updateActivity, deleteActivity, reset} from '../../../features/activity/activitySlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {getCrops} from 'features/crops/cropSlice'
import ActivityConnectionsDisplay from './ActivityConnectionsDisplay'
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { getAllEquipment } from 'features/equipment/equipmentSlice'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getCustomers } from 'features/customers/customerSlice'
import { InputSelection } from 'types/ui'
import {ActivityModel, CropModel} from "@asirisos/types";
import { getInfrastructures } from 'features/infrastructure/infrastructureSlice'
import { getFields } from 'features/fields/fieldSlice'
import { getYields } from 'features/yield/yieldSlice'


function ActivityPage() {


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const {t} = useTranslation("activity");
    const {activityId} = useParams()
    const dispatch = useDispatch<AppDispatch>()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getFarmAccounts())
        dispatch(getAllEquipment())
        dispatch(getAllLiveStock())
        dispatch(getAllInventory())
        dispatch(getAllStorage())
        dispatch(getYields())
        dispatch(getInfrastructures())
        dispatch(getFields())
        dispatch(getCustomers())
        if (activityId !== undefined)
            dispatch(getActivity(activityId))
    }, [dispatch, activityId])


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {activity, status} = useSelector((state: RootState) => state.activity)

    const {crops} = useSelector((state : RootState) => state.crop )
    const {equipments} = useSelector((state : RootState) => state.equipment )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory ) 
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {customers} = useSelector((state: RootState) => state.customer )
    const {fields} = useSelector((state: RootState) => state.field)
    const {infrastructures} = useSelector((state: RootState) => state.infrastructure)
    const {yields} = useSelector((state: RootState) => state.yield)


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS - DROP DOWN   ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const cropSelections = crops.map((crop: CropModel) => { return new InputSelection(crop.cropName, crop._id)})

    const equipmentSelections = equipments.map((equipment) => {return new InputSelection(equipment.name, equipment._id)})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})

    const yieldSelections = yields.map((singleYield) => {return  new InputSelection(singleYield.title, singleYield._id)})

    const fieldSelections = fields.map((field) => {return  new InputSelection(field.fieldName, field._id)})

    const infrastructureSelections = infrastructures.map((infrastructure) => {return  new InputSelection(infrastructure.infrastructureName, infrastructure._id)})

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE STATE - ACTIVITY DATA   ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [formData, setFormData] = useState({
        farmId: '',
        title: '',
        connectionCategory: '',
        description: '',
        employee: '',
        deadline: '',
        equipment: '',
        crop: '',
        liveStock: '',
        activityType: '',
        inventory: '',
        storage: '',
        yieldConnection: '',
        field: '',
        infrastructure: '',
        customer: '',
        assignedTo: '',
        assignedToObject: {
            job_title: '',
            phone: '',
            name: '',
            email: '',
        },
        storageObject: {
            _id: '',
            name: '',
            description: '',
            quantity: 0,
            comments: '',
            crop: '',
            category: ''
        },
        cropObject: {
            _id: '',
            farmId: '',
            cropName: '',
            developmentStage: '',
            stageProgress: '',
            landArea: 0,
            areaDenomination: '',
            numberOfTrees: 0,
            expectedYield: 0,
            expectedYieldDenom: '',
            stockHarvested: 0,
            stockFulfilment: 0,
            salePrice: 0,
            salePriceDenom: '',
            expectedCost: 0,
            expectedCostDenom: '',
            waterNutrition: '',
            nitrogenNutrition: '',
            phosphorusNutrition: '',
            potassiumNutrition: ''
        },
        liveStockObject: {
            name: '',
            category: '',
            weight: 0,
            description: '',
            _id: '',
            sex: ''
        },
        employeeObject: {
            name: '',
            email: '',
            phone: '',
            job_title: '',
            date_of_hire: '',
            recruitment_details: '',
            salary: '',
            qualifications: '',
            accountID: '',
            _id: '',
            access: [],
        },
        equipmentObject: {
            _id: '',
            // user?: UserModel,     // The model is there - not using rn
            farmId: '',
            type: '',
            name: '',
            mileage: '',
            vin: ''
        },
        inventoryObject: {
            _id: '',
            name: '',
            farmID: '',
            description: '',
            type: '',
            quantity: 0,
            comments: ''
        },
        customerObject: {
            _id: '',
            // user?: UserModel,    // The model is there - not using rn 
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        yieldObject: {
            title: ''
        },
        infrastructureObject: {
            infrastructureName: ''
        },
        fieldObject: {
            fieldName: ''
        },
        farmid: ''
    })


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATE ENTRIES - DESTRUCTURE   //////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {farmId,
        title,
        connectionCategory,
        description,
        crop,
        employee,
        deadline,
        equipment,
        liveStock,
        inventory,
        infrastructure,
        field,
        yieldConnection,
        storage,
        customer,
        activityType,
        assignedTo,
        storageObject,
        cropObject,
        assignedToObject,
        liveStockObject,
        employeeObject,
        equipmentObject,
        inventoryObject,
        customerObject,
    } = formData



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE EFFECT - FORM DATA FOR ON CHANGE   /////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {

        if (status !== Status.Pending && activity !== undefined) setFormData((prevState) => ({
            ...prevState,
            title: activity.title!,
            description: activity.description!,
            crop: activity.crop!,
            deadline: activity.deadline!,
            connectionCategory: activity.connectionCategory!,
            employee: activity.employee!,
            equipment: activity.equipment!,
            liveStock: activity.liveStock!,
            inventory: activity.inventory!,
            storage: activity.storage!,
            field: activity.field!,
            infrastructure: activity.infrastructure!,
            yieldConnection: activity.yieldConnection!,
            customer: activity.customer!,
            assignedTo: activity.assignedTo!,
            activityType: activity.activityType!,
        }));
    }, [status])




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON CHANGE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        console.log(e.target)
        console.log(e)
        console.log('name', e.target.name)
        console.log('value', e.target.value)

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  HANDLE CHANGE - NOT USED   /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        console.log('handle Change', e.target.name, e.target.value)
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        const activityData: ActivityModel = {
            // assignedToObject: {email: "", job_title: "", name: "", phone: ""},
            // farmid: "",
            // liveStockObject: {_id: "", category: "", description: "", name: "", sex: "", weight: 0},
            // user: undefined,
            title,
            connectionCategory,
            activityType,
            description,
            employee,
            deadline,
            equipment,
            crop,
            yieldConnection,
            infrastructure,
            field,
            // _id: activityId!,
            liveStock,
            inventory,
            storage,
            customer,
            assignedTo
        }
        dispatch(updateActivity(activityData))
            .unwrap()
            .then(() => {
                toast.success(t("activity:activityUpdated"))
            })
            .catch(() => {
                toast.error(t("activity:activityUpdateFailed"))
            })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON KEY DOWN   //////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON BLUR   //////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const activityData: ActivityModel = {
            title,
            description,
            activityType,
            crop,
            deadline,
            farmId,
            connectionCategory,
            employee,
            field,
            infrastructure,
            yieldConnection,
            equipment,
            liveStock,
            inventory,
            storage,
            customer,
            assignedTo,
            // _id: activityId!
        }
        dispatch(updateActivity(activityData))
            .unwrap()
            .then(() => {
                toast.success(t("activity:activityUpdated"))
            })
            .catch(() => {
                toast.error(t("activity:activityUpdateFailed"))
            })
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON DELETE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleDelete = (activityId: string) => {
        dispatch(deleteActivity(activityId))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ACTIVITY GET -  ON FAILIURE    /////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (status === Status.Failure) {
        toast.error(t("activity:activityGetFailed"))
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATUS CHECK   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (status === Status.Pending && activityId != '') {
        return <Spinner/>
    } else {
        return (
            <>
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////    LIVE UPDATE SECTION     ////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
                <div className="grid grid-cols-3 gap-3">
                    
                    {/* ///////////////////////////////////    IMAGE     ////////////////////////////////////////// */}
                    <div className='-my-1 col-span-1 justify-self-center self-center'>
                        <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl" src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                    </div>


                    {/* ///////////////////////////////////    INLINE EDITS     ////////////////////////////////////////// */}
                    <div className='col-span2 justify-start justify-self-start'>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:title")} value={title}
                                    id={"title"}
                                    name={"title"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:activityType")} value={activityType}
                                    id={"activityType"}
                                    name={"activityType"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:activityId")}
                                    value={activityId}
                                    id={"activityId"}
                                    name={"activityId"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("account:description")}
                                    value={description}
                                    id={"description"}
                                    name={"description"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:date")} value={deadline}
                                    id={"date"}
                                    name={"date"}/>

                    </div>
                </div>
            </div>

            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////    ACTIVITY CONNECTIONS DISPLAY ////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <ActivityConnectionsDisplay activity={activity} />

            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////    ACTIVITY OWNER DISPLAY      ////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="bg-white mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
                <div className="border-2 rounded-lg border-blue-500 px-2" >
                    <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                        <div className='flex flex-col' >
                            <h1>Activity Contact</h1>
                            <h1>{activity?.assignedToObject?.name}</h1>
                            <h1>{activity?.assignedToObject?.email}</h1>
                            <h1>{activity?.assignedToObject?.phone}</h1>
                            <h1>{activity?.assignedToObject?.job_title}</h1>
                        </div>
                    </Link>
                </div>
            </div>

            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////    ACTIVITY TYPE = CHANGE      ////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            { activity?.activityType === "change" ? (
                <>
                    <div className="bg-white mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] flex justify-center ">
                        <h1 className='font-bold mr-1'> {`${activity?.changedField}`}</h1>
                        <h1> has been changed from</h1>
                        <h1 className='font-bold mx-1'>{activity?.changedFieldBefore}</h1> 
                        <h1> to </h1> 
                        <h1 className='font-bold mx-1 '>{activity?.changedFieldAfter}</h1>
                        <h1> by </h1> 
                        <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                            <h1 className='font-bold ml-1 '>{activity?.assignedToObject?.name}</h1>
                        </Link>
                        {/* <h1>{`${activity?.changedField} has been changed from ${activity?.changedFieldBefore} to ${activity?.changedFieldAfter}`}</h1> */}
                    </div>
                </>
            ) : (
                <></>
            ) }

            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////    ACTIVITY TYPE = DELETE      ////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {activity?.activityType === 'delete' ? (
                <>
                    <div className="bg-white mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] justify-center ">
                        <div className='flex flex-col'>
                            <h1>Items with the following ids</h1>
                        {activity?.deletedIds?.map(text => 
                            (
                            <h1 className='text-md'>{text}</h1> ) )}
                        </div>
                        <div className='flex flex-row justify-center' >
                            <h1 className=''>were deleted by</h1>
                                <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                                    <h1 className='font-bold ml-1 '>{activity?.assignedToObject?.name}</h1>
                                </Link>
                        </div>
                    </div>
                </>
            ) : (<></>) }
            </>
        )
    }
}


export default ActivityPage