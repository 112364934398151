import React, {useState, ChangeEvent, FormEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import { emailSignUp } from 'features/auth/authSlice';
import {Link} from 'react-router-dom'
import FormCard from 'UI/components/shared/FormCard';

const animation = require ('./asiris_animation.gif')
const farmCloud = require ('./farm_cloud.png')
const marketplaces = require ('./marketplaces.png')
const singleDataSource = require('./single_data_source.png')

function SignUpLandingPage() {

    const {t} = useTranslation("organization")

    const [formData, setFormData] = useState({
        email: '',
        phone: '',
    })

    const {email, phone} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        const emailSignUpData = {
            email, phone
        }

        console.log("Email Data before dispatch",emailSignUpData)

        dispatch(emailSignUp(emailSignUpData))
            // .unwrap()
            // .then(() => {
            //     toast.success(t("organization:orgAdded"))
            //     navigate('/')
            // })
            // .catch(() => {
            //     toast.error(t("organization:orgAddFailed"))
            //     // navigate('/farm-add-form')
            // })
    }


  return (
    <>
{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* MOBILE VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}

    <div className='lg:hidden sm:visible' >


        {/********************************************/}
        {/********************* FIRST SCREEN ***********************/}
        {/********************************************/}

        <div className=' w-screen flex flex-col justify-start bg-[#1e2222] pb-10'>
            <div className='bg-indigo-500 flex justify-around'>    
                <div className='flex flex-row justify-center py-2' >
                    <h1 className='text-2xl font-light text-white' >ASIRIS OS - </h1>
                    <h1 className='text-lg font-light text-white pt-1 pl-1' >your farm assistant</h1>
                </div>
                <Link className="w-1/5 my-1 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 border-2 border-green-500 flex justify-center " to={"/login"} >
                    <h1 className='text-white text-xl pt-1 font-bold'>Login</h1>
                </Link>
            </div>

            <div className='text-start px-3'>
                <h1 className=' text-5xl font-bold text-white pt-8' >Everything you need 
                to <a className="underline text-indigo-300 decoration-green-500">digitize</a> and <a className="underline text-indigo-300 decoration-green-500">grow</a> your 
                farm in <a className="underline text-indigo-300 decoration-green-500">one place</a> </h1>
            </div>

            <div className='flex flex-row justify-center items-center pt-5'>
                <img className='w-full justify-center items-center' src={animation} alt="" />
            </div>

            <div className='pt-10 flex justify-center'>
                <Link className="w-4/5 px-4 py-3 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 border-2 border-green-400 flex justify-center " to={"/sign-up-form"} >
                
                    <h1 className='text-white text-2xl font-bold'>Free sign up</h1>
                    <i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-8"></i>
                
                </Link>
            </div>
        </div>

        {/********************************************/}
        {/********************* FIRST SCREEN END ***********************/}
        {/********************************************/}

        {/********************************************/}
        {/********************* SECOND SCREEN ***********************/}
        {/********************************************/}

        <div className=' w-screen py-5 flex flex-col justify-center bg-sky-800'>
            <div className='items-start flex flex-col px-3 pb-3'>
                <h1 className='text-start font-bold text-5xl pt-1 text-white'>Create a <a className="underline text-indigo-300 decoration-green-500">digital copy</a> of all farm components <i className="fa-solid fa-arrow-down fa-beat text-4xl text-green-500 pl-3"></i> </h1>
            </div>
            <div className='flex flex-row justify-center items-center'>
                <img className='max-sm:w-5/4 justify-center items-center' src={farmCloud} alt="" />
            </div>

            <div className='text-start px-4 pt-7'>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className=" decoration-cyan-500 font-normal text-blue-300">Track</a> and <a className=" text-blue-300 decoration-cyan-500 font-normal">store</a> all 
                    operational <a className=" text-blue-300 decoration-cyan-500 font-normal">changes</a></h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className=" text-blue-300 decoration-cyan-500 font-normal">Task</a> <a className=" text-blue-300 decoration-cyan-500 font-normal">creation</a> & <a className=" text-blue-300 decoration-cyan-500 font-normal">management</a> </h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className=" text-blue-300 decoration-cyan-500 font-normal">Remote</a>, <a className=" text-blue-300 decoration-cyan-500 font-normal">shared</a>
                    , &<a className=" text-blue-300 decoration-cyan-500 font-normal pl-2">digital</a> <a className=" text-blue-300 decoration-cyan-500 font-normal">workspace </a> </h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- Simplified  <a className=" text-blue-300 decoration-cyan-500 font-normal">infromation</a> exchange </h1>
            </div> 
        </div>

        {/********************************************/}
        {/********************* SECOND SCREEN END ***********************/}
        {/********************************************/}

        {/********************************************/}
        {/********************* THIRD SCREEN ***********************/}
        {/********************************************/}

        <div className=' w-screen  flex flex-col justify-start bg-[#1e2222] pb-10'> 
                    
            <div className='items-start flex flex-col px-3 py-3'>
                <h1 className='text-start font-bold text-5xl pt-1 text-white'>Simplify <a className="underline text-indigo-300 decoration-green-500">purchasing</a> and <a className="underline text-indigo-300 decoration-green-500">selling</a> 
                <i className="fa-solid fa-arrow-down fa-beat text-4xl text-green-500 pl-3"></i></h1>
            </div>

            <div className=''>
                <img className='justify-center items-center w-full' src={marketplaces} alt="" />
            </div>            
                            
            <div className='items-start text-start flex flex-col px-4'>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className="decoration-cyan-500 font-normal text-blue-300">Direct-to-consumer</a> sales</h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className="decoration-cyan-500 font-normal text-blue-300">Wholesale</a> market for <a className="decoration-cyan-500 font-normal text-blue-300">commercial</a> sales</h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className="decoration-cyan-500 font-normal text-blue-300">Farm-to-farm</a> market
                for <a className="decoration-cyan-500 font-normal text-blue-300">equipment</a>, <a className="decoration-cyan-500 font-normal text-blue-300">services</a>, and <a className="decoration-cyan-500 font-normal text-blue-300">inputs</a> </h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- Personal <a className="decoration-cyan-500 font-normal text-blue-300">website</a> with <a className="decoration-cyan-500 font-normal text-blue-300">NO code</a> or hosting </h1>
            </div>      

        </div>

        {/********************************************/}
        {/********************* THIRD SCREEN END ***********************/}
        {/********************************************/}


        {/********************************************/}
        {/********************* FOURTH SCREEN ***********************/}
        {/********************************************/}

        <div className=' w-screen  flex flex-col justify-start bg-sky-900 pb-10'> 
                    
            <div className='items-start flex flex-col px-3 pt-3'>
                <h1 className='text-start font-bold text-5xl pt-1 text-white'><a className="underline text-indigo-300 decoration-green-500">Single source of truth</a> for all your data needs
                <i className="fa-solid fa-arrow-down fa-beat text-4xl text-green-500 pl-3"></i> </h1>
            </div>

            <div className='pr-'>
                <img className='justify-center items-center w-full' src={singleDataSource} alt="" />
            </div>            
                            
            <div className='items-start text-start flex flex-col px-4'>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className="decoration-cyan-500 font-normal text-blue-300">Integrate</a> all <a className="decoration-cyan-500 font-normal text-blue-300">data</a> in a single place </h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- Simple <a className="decoration-cyan-500 font-normal text-blue-300">integration</a> of new IOT <a className="decoration-cyan-500 font-normal text-blue-300">sensors</a></h1>
                <h1 className='font-light text-xl text-slate-200 pb-1'>- <a className="decoration-cyan-500 font-normal text-blue-300">Open-source</a> development of <a className="decoration-cyan-500 font-normal text-blue-300">IOT</a> <a className="decoration-cyan-500 font-normal text-blue-300">devices</a> </h1>
            </div>      

        </div>

        {/********************************************/}
        {/********************* FOURTH SCREEN END ***********************/}
        {/********************************************/}

        {/********************************************/}
        {/********************* FIFTH SCREEN ***********************/}
        {/********************************************/}

        <div className='w-screen  justify-start bg-white px-7 pt-5 pb-10'>     
            
            {/* SATTELITE IMAGING START */}
                        <div className= 'col-span-1 bg-indigo-400 m-4 py-4 my-5 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                            <i className="fa-solid fa-eye-low-vision fa-beat text-green-400 text-6xl pt-3"></i>
            
                            <h1 className='pt-6 font-semibold text-2xl underline decoration-green-400 text-white' >Restricted Access</h1>
                                <div className='flex flex-row justify-center' >
                                    <div className='pt-5 flex flex-col items-start' >
                                        <h1 className='text-xl font-semibold'>Restrict access to</h1>
                                        <h1 className='text-xl font-semibold'>employee accounts based</h1>
                                        <h1 className='text-xl font-semibold'>on their responsibilities</h1>
                                    </div>
                                </div>
                        </div>
            {/* SATTELITE IMAGING END */}
            
            
            {/* SATTELITE IMAGING START */}
                        <div className= 'col-span-1 bg-indigo-400 m-4 py-4 my-5 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                            <i className="fa-solid fa-satellite fa-beat text-green-400 text-6xl pt-3"></i>
            
                            <h1 className='pt-6 font-semibold text-2xl text-white underline decoration-green-400' >Sattelite Imaging</h1>
                                <div className='flex flex-row justify-center' >
                                    <div className='pt-5 flex flex-col items-start' >
                                        <h1 className='text-xl font-semibold'>Manage and track your</h1>
                                        <h1 className='text-xl font-semibold'>farm from a bird's</h1>
                                        <h1 className='text-xl font-semibold'>eye view</h1>
                                    </div>
                                </div>
                        </div>
            {/* SATTELITE IMAGING END */}
            
            
            {/* CUSTOMER RELATIONS START */}
                        <div className= 'col-span-1 bg-indigo-400 m-4 py-4 my-5 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                            <i className="fa-solid fa-people-group fa-beat text-green-400 text-6xl pt-3"></i>
            
                            <h1 className='pt-6 font-semibold text-2xl text-white  underline decoration-green-400' >Customer Relation</h1>
                                <div className='flex flex-row justify-center' >
                                    <div className='pt-5 flex flex-col items-start' >
                                        <h1 className='text-xl font-semibold'>Store and manage you</h1>
                                        <h1 className='text-xl font-semibold'>customer info and</h1>
                                        <h1 className='text-xl font-semibold'>interactions</h1>
                                    </div>
                                </div>
                        </div>
            {/* CUSTOMER RELATIONS END */}
            
            
            {/* SUPPLY CHAIN START */}
                        <div className= 'col-span-1 bg-indigo-400 m-4 py-4 my-5 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                            <i className="fa-solid fa-industry fa-beat text-green-400 text-6xl pt-3"></i>
            
                            <h1 className='pt-8 font-semibold text-2xl text-slate-200 underline decoration-green-400' >Supply Chain Access</h1>
                                <div className='flex flex-row justify-center'>
                                    <div className='pt-5 flex flex-col items-start'>
                                        <h1 className='text-xl font-semibold'>Transparent view of the</h1>
                                        <h1 className='text-xl font-semibold'>decentralized food</h1>
                                        <h1 className='text-xl font-semibold'>supply chain </h1>
                                    </div>
                                </div>
                        </div>
            {/* SUPPLY CHAIN END */}
                    </div>
            
                    {/* ********************************* */}
                    {/* OTHER FEATURES SCREEN END*/}
                    {/* ********************************* */}
            


        
        {/********************************************/}
        {/********************* FIFTH SCREEN END ***********************/}
        {/********************************************/}


    <div className='bg-[#1e2222]'>
        <div className='py-6 flex justify-center'>
            <Link className="w-4/5 px-4 py-3 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 border-2 border-green-400 flex justify-center " to={"/sign-up-form"} >
                <h1 className='text-white text-2xl font-bold'>Free sign up</h1>
                <i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-8"></i>
            </Link>
        </div>
    </div>

    </div>


{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* MOBILE VERSION END ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}



{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* DESKTOP VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}

    <div className='lg:visible invisible max-sm:hidden' >
        {/* ********************************* */}
        {/* FIRST SCREEN */}
        {/* ********************************* */}

        <div className=' w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#1e2222]'>

            <div className='bg-indigo-500 flex justify-around'>    
                <div className='flex flex-row justify-center py-2' >
                    <h1 className='max-sm:text-3xl max-sm:font-light text-4xl font-thin text-white' >ASIRIS OS - </h1>
                    <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-white pt-1 pl-3' >your farm assistant</h1>
                </div>
                <Link className=" px-5 my-1 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 hover:bg-indigo-600 border-2 border-green-500 flex justify-center " to={"/login"} >
                    <h1 className='text-white text-xl pt-2 font-bold'>Login</h1>
                </Link>
            </div>
            
            <div className='pb-6 max-sm:pb-10 flex flex-col justify-start items-center'>

                <div className='items-start pt-10 '>
                    <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-start font-bold text-7xl pt-1 text-white'>Everything you need 
                    to <a className="underline text-indigo-300 decoration-green-500">digitize</a> <br/> and <a className="underline text-indigo-300 decoration-green-500">grow</a> your farm 
                    in <a className="underline text-indigo-300 decoration-green-500">one place</a></h1>
                </div>

                <Link className="hover:bg-indigo-600 mt-16 w-1/5 px-4 py-4 rounded-tl-none rounded-br-none 
                    rounded-lg bg-indigo-500 border-2 border-slate-500 flex justify-center shadow-[2px_1px_40px_1px_rgba(201,201,201,0.20)] " to={"/sign-up-form"} >
                    <h1 className='text-white text-3xl font-bold'>Free sign up<i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-4"></i></h1>
                </Link>

            </div>

            <div className='flex flex-row justify-center items-center'>
                <img className='mt-4 max-sm:w-full w-5/12 justify-center items-center' src={animation} alt="" />

                {/* <div className='shadow-[2px_1px_70px_3px_rgba(201,201,201,0.40)] rounded-md w-1/4'>
                    <div className='bg-indigo-500 max-sm:mt-4 rounded-t-md py-4 px-6 '>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-light text-3xl pt-1  text-white'>1 YEAR FREE</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-thin text-2xl text-white'>when you sign up today</h1>
                    </div>

                    <div className='bg-slate-200 rounded-md rounded-t-none'>
                        <form onSubmit={e => onSubmit(e)} className='flex-row py-10 '>
                            
                            <div className='py-8'>
                                <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" placeholder='Email' onChange={onChange} name="email" value={email} id='email'/>    
                            </div>

                            <div className='py-3'>
                                <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" placeholder='Phone' onChange={onChange} name="email" value={email} id='email'/>    
                            </div>
                                
                            <div className='pt-10'>
                                <button className="bg-indigo-500 max-sm:text-2xl max-sm:px-16 border-0 px-10 py-2 w-2/4 rounded-lg text-white text-2xl shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] font-medium hover:bg-indigo-700 ">{t("organization:signup")}</button>
                            </div>     

                        </form>
                    </div>
                </div> */}

            </div> 
        </div>



        {/* <div className='bg-indigo-400 py-1'></div> */}


        {/* ********************************* */}
        {/* SECOND SCREEN */}
        {/* ********************************* */}
{/* 
        <div className='shadow-[2px_1px_50px_3px_rgba(201,201,201,0.47)]'>
                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl py-2 text-indigo-500'>FARM CLOUD</h1>
        </div> */}

        <div className=' w-screen py-20 flex flex-col max-sm:justify-start justify-center bg-sky-800 pt-20'>
            
            <div className='flex flex-row justify-center items-center'>
                <img className='max-sm:w-full w-1/3 justify-center items-center' src={farmCloud} alt="" />

                <div className='max-sm:mt-4 rounded-t-md py-4 px-6 flex-col full '>
                    <div className='items-start pl-10'>
                        <div className='flex flex-col items-start'>
                            <div className='items-start flex flex-col pb-16'>
                                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-6xl py-1 text-white'>FARM CLOUD<i className="fa-solid fa-arrow-left fa-beat text-5xl text-green-500 pl-10"></i></h1>
                            </div>
                            <div className='items-start flex flex-col pb-16'>
                                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'>Create a <a className="underline text-indigo-300 decoration-green-500">digital copy</a> of all</h1>
                                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'>farm components</h1>
                            </div>
                            
                            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-normal text-2xl text-slate-200 pb-1'>- Automatically <a className="underline decoration-cyan-500 font-semibold text-blue-300">track</a> and <a className="underline text-blue-300 decoration-cyan-500 font-semibold">store</a> all 
                                operational <a className="underline text-blue-300 decoration-cyan-500 font-semibold">changes</a></h1>
                            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-light font-normal text-2xl text-slate-200 pb-1'>- Simplified & 
                                automated <a className="underline text-blue-300 decoration-cyan-500 font-semibold">task</a> <a className="underline text-blue-300 decoration-cyan-500 font-semibold">creation</a> & <a className="underline text-blue-300 decoration-cyan-500 font-semibold">management</a> </h1>
                            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-light font-normal text-2xl text-slate-200 pb-1'>- Create <a className="underline text-blue-300 decoration-cyan-500 font-semibold">remote</a>, <a className="underline text-blue-300 decoration-cyan-500 font-semibold">shared</a>
                                , &<a className="underline text-blue-300 decoration-cyan-500 font-semibold pl-2">digital</a> <a className="underline text-blue-300 decoration-cyan-500 font-semibold">workspace </a> </h1>
                            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-normal font-normal text-2xl text-slate-200 pb-1'>- Simplified  <a className="underline text-blue-300 decoration-cyan-500 font-semibold">infromation</a> exchange </h1>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        {/* ********************************* */}
        {/* SECOND SCREEN END */}
        {/* ********************************* */}




        {/* ********************************* */}
        {/* OTHER FEATURES SCREEN*/}
        {/* ********************************* */}

        {/* //////////////////////////////// DIVIDER //////////////////////////////// */}
        {/* <div className='bg-indigo-400 py-1'></div> */}
        {/* //////////////////////////////// DIVIDER //////////////////////////////// */}

        <div className=''>
            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl py-1 text-indigo-500'></h1>
        </div>

        <div className='w-screen grid grid-cols-4 gap-8 max-sm:justify-start justify-start bg-white px-7 py-10 pb-10'>     
            
{/* SATTELITE IMAGING START */}
            <div className= 'col-span-1 bg-indigo-400 m-4 py-4 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                <i className="fa-solid fa-eye-low-vision fa-beat text-green-400 text-6xl pt-3"></i>

                <h1 className='pt-6 font-semibold text-3xl underline decoration-green-400 text-white' >Restricted Access</h1>
                    <div className='flex flex-row justify-center' >
                        <div className='pt-5 flex flex-col items-start' >
                            <h1 className='text-2xl font-semibold'>Restrict access to</h1>
                            <h1 className='text-2xl font-semibold'>employee accounts based</h1>
                            <h1 className='text-2xl font-semibold'>on their responsibilities</h1>
                        </div>
                    </div>
            </div>
{/* SATTELITE IMAGING END */}


{/* SATTELITE IMAGING START */}
            <div className= 'col-span-1 bg-indigo-400 m-4 py-4 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                <i className="fa-solid fa-satellite fa-beat text-green-400 text-6xl pt-3"></i>

                <h1 className='pt-6 font-semibold text-3xl text-white underline decoration-green-400' >Sattelite Imaging</h1>
                    <div className='flex flex-row justify-center' >
                        <div className='pt-5 flex flex-col items-start' >
                            <h1 className='text-2xl font-semibold'>Manage and track your</h1>
                            <h1 className='text-2xl font-semibold'>farm from a bird's</h1>
                            <h1 className='text-2xl font-semibold'>eye view</h1>
                        </div>
                    </div>
            </div>
{/* SATTELITE IMAGING END */}


{/* CUSTOMER RELATIONS START */}
            <div className= 'col-span-1 bg-indigo-400 m-4 py-4 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                <i className="fa-solid fa-people-group fa-beat text-green-400 text-6xl pt-3"></i>

                <h1 className='pt-6 font-semibold text-3xl text-white  underline decoration-green-400' >Customer Relation</h1>
                    <div className='flex flex-row justify-center' >
                        <div className='pt-5 flex flex-col items-start' >
                            <h1 className='text-2xl font-semibold'>Store and manage you</h1>
                            <h1 className='text-2xl font-semibold'>customer info and</h1>
                            <h1 className='text-2xl font-semibold'>interactions</h1>
                        </div>
                    </div>
            </div>
{/* CUSTOMER RELATIONS END */}


{/* SUPPLY CHAIN START */}
            <div className= 'col-span-1 bg-indigo-400 m-4 py-4 rounded-md shadow-[2px_1px_30px_1px_rgba(0,0,0,0.39)]' >
                <i className="fa-solid fa-industry fa-beat text-green-400 text-6xl pt-3"></i>

                <h1 className='pt-8 font-semibold text-3xl text-slate-200 underline decoration-green-400' >Supply Chain Access</h1>
                    <div className='flex flex-row justify-center'>
                        <div className='pt-5 flex flex-col items-start'>
                            <h1 className='text-2xl font-semibold'>Transparent view of the</h1>
                            <h1 className='text-2xl font-semibold'>decentralized food</h1>
                            <h1 className='text-2xl font-semibold'>supply chain </h1>
                        </div>
                    </div>
            </div>
{/* SUPPLY CHAIN END */}
        </div>

        {/* ********************************* */}
        {/* OTHER FEATURES SCREEN END*/}
        {/* ********************************* */}





{/* //////////////////////////////// DIVIDER //////////////////////////////// */}
{/* <div className='bg-indigo-400 py-1'></div> */}
{/* //////////////////////////////// DIVIDER //////////////////////////////// */}


        {/* ********************************* */}
        {/* FOURTH SCREEN */}
        {/* ********************************* */}

        {/* <div className=''>
            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl py-1 bg-[#1e2222] text-white'>DATA</h1>
        </div> */}

        <div className='w-screen grid grid-cols-2 max-sm:justify-start justify-start bg-[#1e2222]'>     

            <div className=''>
                <img className='max-sm:w-full justify-center items-center px-20' src={singleDataSource} alt="" />
            </div>
                    
            <div className='flex flex-col items-center justify-center'>
                <div>
                    <div className='items-start flex flex-col pb-16'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-6xl py-1 text-white'>DATA <i className="fa-solid fa-arrow-left fa-beat text-5xl text-green-500 pl-10"></i></h1>
                    </div>
                    <div className='items-start flex flex-col pb-16'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'> <a className=" text-indigo-300 underline decoration-green-500">Single source of truth</a> for</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'>all your data needs</h1>
                    </div>
                    
                    <div className='items-start flex flex-col pb-6'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-normal text-2xl text-slate-200 pb-1'>- <a className="underline text-blue-300 decoration-cyan-500 font-semibold">Integrate</a> all <a className="underline text-blue-300 decoration-cyan-500 font-semibold">data</a> sources in a single place</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-normal font-normal text-2xl text-slate-200 pb-1'>- Simple <a className="underline text-blue-300 decoration-cyan-500 font-semibold">integration</a> of new <a className="underline text-blue-300 decoration-cyan-500 font-semibold">sensors</a> and IOT</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-normal font-normal text-2xl text-slate-200 pb-1'>- <a className="underline text-blue-300 decoration-cyan-500 font-semibold">Open-source</a> development of new <a className="underline text-blue-300 decoration-cyan-500 font-semibold">IOT</a> <a className="underline text-blue-300 decoration-cyan-500 font-semibold">devices</a> </h1>
                    </div>
                </div>
            </div>                    

        </div>

        {/* ********************************* */}
        {/* FOURTH SCREEN END */}
        {/* ********************************* */}


{/* //////////////////////////////// DIVIDER //////////////////////////////// */}
{/* <div className='bg-indigo-400 py-1'></div> */}
{/* //////////////////////////////// DIVIDER //////////////////////////////// */}


        {/* ********************************* */}
        {/* THIRD SCREEN */}
        {/* ********************************* */}

        {/* <div className='shadow-[2px_1px_500px_30px_rgba(201,201,201,0.47)' >
            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl py-1 text-indigo-500'>MARKETPLACES</h1>
        </div> */}

        <div className='w-screen grid grid-cols-2 max-sm:justify-start justify-start bg-sky-800  '>     
                    
            <div className='flex flex-col items-center justify-center'>
                <div>
                    <div className='items-start flex flex-col pb-16'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-6xl py-1 text-white'>MARKETPLACES<i className="fa-solid fa-arrow-right fa-beat text-5xl text-green-500 pl-10"></i></h1>
                    </div>
                    <div className='items-start flex flex-col pb-16'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'>Simplify  <a className=" text-indigo-300 underline decoration-green-500">purchasing</a> </h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-bold text-5xl pt-1 text-white'>and <a className=" text-indigo-300 underline decoration-green-500">selling</a></h1>
                    </div>
                    
                    <div className='items-start flex flex-col pb-6'>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-normal text-2xl text-slate-200 pb-1'>- <a className="underline text-blue-300 decoration-cyan-500 font-semibold">Direct-to-consumer</a> sales</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-light font-normal text-2xl text-slate-200 pb-1'>- <a className="underline text-blue-300 decoration-cyan-500 font-semibold">Wholesale</a> marketplace for <a className="underline text-blue-300 decoration-cyan-500 font-semibold">commercial</a> sales</h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-normal font-normal text-2xl text-slate-200 pb-1'>- <a className="underline text-blue-300 decoration-cyan-500 font-semibold">Farm-to-farm</a> marketplace
                        for <a className="underline text-blue-300 decoration-cyan-500 font-semibold">equipment</a> , <a className="underline text-blue-300 decoration-cyan-500 font-semibold">services</a> , and <a className="underline text-blue-300 decoration-cyan-500 font-semibold">inputs</a> </h1>
                        <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-normal font-normal text-2xl text-slate-200 pb-1'>- Personalized <a className="underline text-blue-300 decoration-cyan-500 font-semibold">website</a> with <a className="underline text-blue-300 decoration-cyan-500 font-semibold">NO code</a> or hosting </h1>
                    </div>
                </div>
            </div>
                    
                
            <div className=''>
                <img className='max-sm:w-full justify-center items-center w-full' src={marketplaces} alt="" />
            </div>

        </div>

        {/* ********************************* */}
        {/* THIRD SCREEN END*/}
        {/* ********************************* */}


        <div className='bg-[#1e2222]'>
            <div className='flex flex-row justify-center py-7' >
                {/* <h1 className='max-sm:text-3xl max-sm:font-light text-4xl font-thin text-white' >ASIRIS OS - </h1>
                <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-white pt-1 pl-3' >your farm assistant</h1> */}

                <Link className="hover:bg-indigo-600 w-1/5 px-4 py-4 rounded-tl-none rounded-br-none 
            rounded-lg bg-indigo-500 border-2 border-slate-500 flex justify-center shadow-[2px_1px_40px_1px_rgba(201,201,201,0.20)] " to={"/sign-up-form"} >
                <h1 className='text-white text-3xl font-bold'>Free sign up<i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-4"></i></h1>
            </Link>
            </div>
            
        </div>

        </div>
    </>
  )
}

export default SignUpLandingPage