import {useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getAccount, getAccounts} from '../../../features/accounts/accountSlice'
import {AppDispatch, RootState} from "../../../app/store";
import {Navigate} from "react-router";
import Spinner from "../../components/Spinner";
import {Status} from "../../../types/slice";
import PolygonMap from '../Map/LeafletPolygon';
import PolygonMap2 from '../Map/PolygonMap';
import DisplaySearchableMap from '../Map/DisplaySearchableMap-1';
import DisplayAllFarm from '../Map/DisplayAllFarm';
import FieldForm from '../Fields/FieldForm';
import Dashboard from './Dashboard';
import { getOrgLocations } from 'features/orgLocations/orgLocationsSlice';
import DisplayAllOrgs from '../Map/DisplayAllOrgs';
// import DisplaySearchableMap from '../Map/DisplaySearchableMap-1';


function Home() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const {accounts, account, status} = useSelector((state: RootState) => state.account)

    console.log(accounts)

    useEffect(() => {
        dispatch(getAccounts())
        // dispatch(getOrgLocations())
    }, [dispatch])

    const onSubmit = (e: React.FormEvent) => {
        dispatch(getAccount((e.target as HTMLInputElement).value))
            .unwrap()
            .then(() => {
                navigate('/my-farm')
            })
            .catch(() => {
                navigate('/home')
            })
    }

   
    

    if (status === Status.Pending)
        return <Spinner/>

    return (
        <>
            

            {status === Status.Success && (accounts === undefined || accounts.length === 0) ? (
                <Navigate to="/setup-start" replace={true} />
            ) : (
                <>
                    { account?._id === undefined ? (
                        accounts.map((account) => (<>
                        <div className='flex flex-row bg-gray-200 rounded-lg shadow-lg justify-center border-2 py-3 my-5'>
                            <div className='py-2'>
                                {`${account.accountType} @ ${account.farm.name}`}
                            </div>

                            <div>
                                <button className='bg-indigo-500 px-4 py-2 rounded-lg ml-8 text-white shadow-xl'
                                    onClick={onSubmit} value={account._id}> Enter Account
                                </button>
                            </div>
                        </div>
                    </>))) : (<><Dashboard/></>) }
                    </>
            )}



            {}
            <div className='py-8'>
                <DisplayAllOrgs/>
            </div>
            {/* <div className='py-8'>
                <DisplayAllFarm/>
            </div> */}
            {/* <div className='py-8' >
                <h1 className="text-xl font-bold py-3" >Search Location / Add Polygon & Mark / Edit Polygon & Mark / Output Polygon Coordinates</h1>
                <PolygonMap/>
            </div> */}

            {/* <div className='py-8' >
                <h1 className="text-xl font-bold py-3" >Same as Map Above + Layer Selection / Earth view + Map</h1>
                <PolygonMap2/>
            </div> */}

            {/* <div className='' >
                <h1 className="text-xl font-bold py-3" >Dynamic Location Search with Text / Select Address / Set Address to Your Farm</h1>
                <DisplaySearchableMap/>
            </div> */}
        </>
    )
}

export default Home