import axios from "axios";
import {AccountModel, OrganizationModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/org-locations'


// Get Organizations
const getOrgLocations = async() => {
    return await axios.get(API_URL + '/getall' )
}


const orgLocationService = {
    getOrgLocations,
}

export default orgLocationService