import React, {ReactNode} from 'react'

type FormCardProps = {
    children?: ReactNode
    reverse? : boolean,
    title? : string
}

function FormCard({children, reverse, title} : FormCardProps)  {
    return (
        <div className='max-sm:w-1/1.5 pb-7' >
        <div className= {`${reverse && 'reverse'} bg-gray-100  max-sm:shadow-none  rounded-lg shadow-2xl max-sm:mx-1  relative mx-9 px-9 mt-16 pt-14 pb-10`} >
            <div className="max-sm:hidden absolute -left-4 -top-4 bg-indigo-500 shadow-lg rounded-xl text-white p-3 ">
                <h1>{title}</h1>
            </div>
            {children}
            {/* <div className='flex justify-center pt-8 p-2' >
                <button class="btn btn-wide btn-primar hover:bg-black">{submitTitle}</button>
            </div> */}
        </div>
        </div>
  )
}

export default FormCard