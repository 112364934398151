import React from 'react'
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './UI/components/Header'
import Sidebar from 'UI/components/sidebar/Sidebar'
import PrivateRoute from './UI/components/PrivateRoute'
import UserPrivateRoute from 'UI/components/UserPrivateRoute'
// Landing Page
import LandingPage from 'UI/pages/LandingPage/LandingPage'
import SignUpLandingPage from 'UI/pages/LandingPage/SignUpLandingPage'
import SignUpFormPage from 'UI/pages/LandingPage/SignUpFormPage'
import ThankYouForSignUp from 'UI/pages/LandingPage/ThankYouForSignUp'
// Auth
import Home from './UI/pages/Home/Home'
import Landing from './UI/pages/Auth/Landing'
// import HomeWidgets from './UI/pages/HomeWidgets'
import Login from './UI/pages/Auth/Login'
import Register from './UI/pages/Auth/Register'
import ForgotPassword from './UI/pages/Auth/ForgotPassword'
// Activity
import Activity from './UI/pages/Activity/Activity'
import ActivityPage from './UI/pages/Activity/ActivityPage'
import ActivityForm from './UI/pages/Activity/ActivityForm'
// Crops
import Crops from './UI/pages/Crops/Crops'
import CropForm from './UI/pages/Crops/CropForm'
import CropPage from './UI/pages/Crops/CropPage'
// Live Stock
import LiveStock from './UI/pages/LiveStock/LiveStock'
import LiveStockPage from './UI/pages/LiveStock/LiveStockPage'
import LiveStockForm from './UI/pages/LiveStock/LiveStockForm'
//Sales
import Sales from './UI/pages/Sales/Sales'
import SaleForm from './UI/pages/Sales/SaleForm'
import SalePage from 'UI/pages/Sales/SalesPage'
//Expenses
import Expenses from 'UI/pages/Expenses/Expenses'
import ExpenseForm from 'UI/pages/Expenses/ExpenseForm'
import ExpensePage from 'UI/pages/Expenses/ExpensePage'
//Customer
import Customers from './UI/pages/Customers/Customers'
import CustomerPage from './UI/pages/Customers/CustomerPage'
import CustomerForm from './UI/pages/Customers/CustomerForm'
// Tasks
import Task from './UI/pages/Tasks/Task'
import TaskForm from './UI/pages/Tasks/TaskForm'
import TaskPage from './UI/pages/Tasks/TaskPage'
// import TaskFormConnections from 'UI/pages/Tasks/TaskFormConnections'
// Employees
import Employees from './UI/pages/Employees/Employees'
import EmployeeForm from './UI/pages/Employees/EmployeeForm'
import EmployeePage from './UI/pages/Employees/EmployeePage'
import ClaimAccount from 'UI/pages/Accounts/ClaimAccount'
// Equipment
import EquipmentForm from './UI/pages/Equipment/EquipmentForm'
import EquipmentPage from './UI/pages/Equipment/EquipmentPage'
import Equipment from './UI/pages/Equipment/Equipment'
// Inventory
import Inventory from './UI/pages/Inventory/Inventory'
import InventoryPage from './UI/pages/Inventory/InventoryPage'
import InventoryForm from './UI/pages/Inventory/InventoryForm'
// Storage
import Storage from './UI/pages/Storage/Storage'
import StoragePage from './UI/pages/Storage/StoragePage'
import StorageForm from './UI/pages/Storage/StorageForm'
// Farm
import Farm from './UI/pages/Farm/Farm'
import FarmAddForm from './UI/pages/Farm/FarmAddForm'
import FarmList from './UI/pages/Farm/FarmList'
import FarmPage from './UI/pages/Farm/FarmPage'
import MyFarmPage from './UI/pages/Farm/MyFarmPage'
import SearchFarm from './UI/pages/Farm/SearchFarm'
import FarmProfile from 'UI/pages/Farm/FarmProfile'
// Yield
import Yield from 'UI/pages/Yield/Yield'
import YieldForm from 'UI/pages/Yield/YieldForm'
import YieldPage from 'UI/pages/Yield/YieldPage'
// import YieldPage from 'UI/pages/Yield/YieldPage'
// Field
import FieldForm from 'UI/pages/Fields/FieldForm'
import Field from 'UI/pages/Fields/Field'
import FieldPage from 'UI/pages/Fields/FieldPage'
// Infrastructure
import Infrastructure from 'UI/pages/Infrastructure/Infrastructure'
import InfrastructurePage from 'UI/pages/Infrastructure/InfrastructurePage'
// Profile
import NewProfile from './UI/pages/Profile/NewProfile'
// Kanban
import Kanban from './UI/pages/Kanban/Kanban'
// Sidebar 2.0
import SetUpStart from './UI/pages/SetUp/SetUpStart'
import SelectOrganization from 'UI/pages/SetUp/SelectOrganization'
import FarmServiceForm from 'UI/pages/SetUp/FarmServicesForm'
import FarmInputsForm from 'UI/pages/SetUp/FarmInputsForm'
import FarmTechForm from 'UI/pages/SetUp/FarmTechForm'
import WholesaleBuyerForm from 'UI/pages/SetUp/WholesaleBuyerForm'
import ProcessingForm from 'UI/pages/SetUp/ProcessingForm'
import WarehousingForm from 'UI/pages/SetUp/WarehousingForm'
import TransportationForm from 'UI/pages/SetUp/TransportationForm'
import RetailBuyerForm from 'UI/pages/SetUp/RetailBuyerForm'
import {store} from "./app/store";
import axios from "axios";

export function SetAxiosAuthHeader() {
    let token = store.getState().auth.user?.token;
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
}

function App() {
    SetAxiosAuthHeader();

    return (
        <>
            <Router>
                <div className='flex flex-row min-h-screen'>


                    {/* <Sidebar2 /> */}

                    <Sidebar/>
                    <div className="w-full text-center bg-white">
                        
                        <Header/>
                        
                        <Routes>
                            {/* <Route path='/' element={<Home/>} /> */}
                            <Route path='/farm-profile/:FarmId' element={<FarmProfile/>}/>
                            <Route path='/field-form' element={<FieldForm/>} />
                            {/* <Route path='/' element={<LandingPage/>} /> */}
                            <Route path='/' element={<SignUpLandingPage/>} />
                            <Route path='/sign-up-form' element={<SignUpFormPage/>} />
                            <Route path='/thanks-for-signup' element={<ThankYouForSignUp/>} />
                            {/* <Route path='/landing2' element={<Landing/>} /> */}

                            {/* <Route path='/farm-profile/:FarmId' element={<FarmProfile/>}/> */}
                            <Route path='/register' element={<Register/>}/>
                            {/* <Route path='/landing' element={<Landing/>}/> */}
                            <Route path='/login' element={<Login/>}/>
                            <Route path='/forgotpassword' element={<ForgotPassword/>}/>

                            {/* Protected Routes */}
                            <Route element={<UserPrivateRoute allowedRoles={['admin', 'hr', 'new']}/>}>
                                <Route path='/setup-start' element={<SetUpStart/>}/>
                                <Route path='/select-org' element={<SelectOrganization/>}/>
                                <Route path='/farm-service-add-form' element={<FarmServiceForm/>}/>
                                <Route path='/farm-inputs-add-form' element={<FarmInputsForm/>}/>
                                <Route path='/farm-tech-add-form' element={<FarmTechForm/>}/>
                                <Route path='/wholesale-add-form' element={<WholesaleBuyerForm/>}/>
                                <Route path='/processing-add-form' element={<ProcessingForm/>}/>
                                <Route path='/warehousing-add-form' element={<WarehousingForm/>}/>
                                <Route path='/transport-add-form' element={<TransportationForm/>}/>
                                <Route path='/retail-add-form' element={<RetailBuyerForm/>}/>

                                <Route path='/farm' element={<Farm/>}/>
                                <Route path='/farm-add-form' element={<FarmAddForm/>}/>
                                <Route path='/farm-list' element={<FarmList/>}/>
                                <Route path='/my-farm' element={<MyFarmPage/>}/>
                                <Route path='/farm/:FarmId' element={<FarmPage/>}/>
                                <Route path='/farm-search' element={<SearchFarm/>}/>
                                <Route path='/claim-account' element={<ClaimAccount/>}/>
                                <Route path='/home' element={<Home/>}/>
                                {/* Profile */}
                                <Route path='/profile' element={<NewProfile/>}/>
                                <Route element={<PrivateRoute allowedRoles={['activity', 'activityEdit']}/>}>
                                    {/* <Route path='/' element={<Home/>} /> */}
                                    {/* ACTIVITY */}
                                    <Route path='/activity' element={<Activity/>}/>
                                    <Route path='/activity/:activityId' element={<ActivityPage/>}/>
                                    <Route path='/activity-form' element={<ActivityForm/>}/>
                                </Route>

                                    {/* Yield */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/yield' element={<Yield/>}/>
                                    <Route path='/yield-form' element={<YieldForm/>}/>
                                    <Route path='/yield/:yieldId' element={<YieldPage/>}/>
                                </Route>

                                    {/* Field */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/field' element={<Field/>}/>
                                    {/* <Route path='/yield-form' element={<YieldForm/>}/> */}
                                    <Route path='/field/:fieldId' element={<FieldPage/>}/>
                                </Route>

                                    {/* Infrastructure */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/infrastructure' element={<Infrastructure/>}/>
                                    {/* <Route path='/yield-form' element={<YieldForm/>}/> */}
                                    <Route path='/infrastructure/:infrastructureId' element={<InfrastructurePage/>}/>
                                </Route>

                                    {/* INVENTORY */}
                                <Route element={<PrivateRoute allowedRoles={['inventory', 'inventoryEdit']}/>}>
                                    <Route path='/inventory' element={<Inventory/>}/>
                                    <Route path='/inventory-form' element={<InventoryForm/>}/>
                                    <Route path='/inventory/:inventoryId' element={<InventoryPage/>}/>
                                </Route>

                                    {/* CUSTOMERS */}
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/customers' element={<Customers/>}/>
                                    <Route path='/customer/:customerId' element={<CustomerPage/>}/>
                                    <Route path='/customer-form' element={<CustomerForm/>}/>
                                </Route>

                                    {/* SALE */}
                                <Route element={<PrivateRoute allowedRoles={['sales', 'salesEdit']}/>}>
                                    <Route path='/sales' element={<Sales/>}/>
                                    <Route path='/sale-form' element={<SaleForm/>}/>
                                    <Route path='/sale/:saleId' element={<SalePage/>} />
                                </Route>

                                    {/* Expense */}
                                <Route element={<PrivateRoute allowedRoles={['sales', 'salesEdit']}/>}>
                                    <Route path='/expenses' element={<Expenses/>}/>
                                    <Route path='/expense-form' element={<ExpenseForm/>}/>
                                    <Route path='/expense/:expenseId' element={<ExpensePage/>} />
                                </Route>

                                    {/* CROPS */}
                                <Route element={<PrivateRoute allowedRoles={['crops', 'cropsEdit']}/>}>
                                    <Route path='/crops' element={<Crops/>}/>
                                    <Route path='/crop-form' element={<CropForm/>}/>
                                    <Route path='/crop/:cropId' element={<CropPage/>}/>
                                </Route>

                                    {/* LIVE STOCK */}
                                <Route element={<PrivateRoute allowedRoles={['liveStock', 'liveStockEdit']}/>}>
                                    <Route path='/livestock' element={<LiveStock/>}/>
                                    <Route path='/livestock/:liveStockId' element={<LiveStockPage/>}/>
                                    <Route path='/livestock-form' element={<LiveStockForm/>}/>
                                </Route>

                                    {/* TASK */}
                                <Route element={<PrivateRoute allowedRoles={['tasks', 'tasksEdit']}/>}>
                                    <Route path='/tasks' element={<Task/>}/>
                                    <Route path='/task-form' element={<TaskForm/>}/>
                                    <Route path='/task/:taskId' element={<TaskPage/>}/>
                                    {/* <Route path='/task-form-connections' element={<TaskFormConnections/>}/> */}
                                </Route>

                                    {/* EQUIPMENT */}
                                <Route element={<PrivateRoute allowedRoles={['equipment', 'equipmentEdit']}/>}>
                                    <Route path='/equipment' element={<Equipment/>}/>
                                    <Route path='/equipment-form' element={<EquipmentForm/>}/>
                                    <Route path='/equipment/:equipmentId' element={<EquipmentPage/>}/>
                                </Route>

                                    {/* EMPLOYEES */}
                                <Route element={<PrivateRoute allowedRoles={['employees', 'employeesEdit']}/>}>
                                    <Route path='/employees' element={<Employees/>}/>
                                    <Route path='/employee-form' element={<EmployeeForm/>}/>
                                    <Route path='/employee/:accountID' element={<EmployeePage/>}/>
                                </Route>

                                    {/* STORAGE */}
                                <Route element={<PrivateRoute allowedRoles={['storage', 'storageEdit']}/>}>
                                    <Route path='/storage' element={<Storage/>}/>
                                    <Route path='/storage/:storageId' element={<StoragePage/>}/>
                                    <Route path='/storage-form' element={<StorageForm/>}/>
                                </Route>

                                    {/* OPERATIONS */}
                                    <Route path='/kanban' element={<Kanban/>}/>

                                    {/* FARM */}
                                    <Route path='/farm' element={<Farm/>}/>
                                    <Route path='/farm-add-form' element={<FarmAddForm/>}/>
                                    <Route path='/farm-list' element={<FarmList/>}/>
                                    <Route path='/farm/:FarmId' element={<FarmPage/>}/>
                                    {/* Cath All */}
                                    {/* <Route path="*" element={<Missing/>} /> */}
                            </Route>
                            {/* <Route element={<PrivateRoute />}>
            </Route> */}
                        </Routes>
                    </div>
                </div>
            </Router>
            <ToastContainer/>
        </>
    )
}

export default App
