import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react'
import {FixMeLater} from "../../../types/dev";

type NumberInputInUnitsProps = {
    dataTitle : string,
    onBlur? : FocusEventHandler,
    denominations? : Array<string> | Array<any>,
    label? : string,
    group? : FixMeLater,
    type : FixMeLater,
    placeholder? : string,
    value? : any,
    id : string,
    onChange? : ChangeEventHandler <any>,
    onKeyDown? : KeyboardEventHandler,
    name : string,
    fixedInputItem?: string,
    dName? : string,
    dValue? : string,
    dId? : string,
    dType? : FixMeLater,
    dPlaceholder? : string
}


function NumberInputInUnits({dataTitle, onBlur, denominations, label, group, type, placeholder, value, id, onChange, onKeyDown, name, fixedInputItem, dName, dValue, dId, dType, dPlaceholder} : NumberInputInUnitsProps) {
    return (
      <div>
        <div className="mt-8 relative rounded-xl shadow-md">



        {/*************************************   FIXED ENTRY IN INPUT   **************************************/}
        {fixedInputItem ? <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="  hover:text-gray-500 sm:text-sm">{fixedInputItem}</span>
        </div> : <></>  }



        {/*************************************   MAIN INPUT FIELD       **************************************/}
          <label className="absolute left-3 insert-y-0 bottom-14 text-slate-500 text-sm">{dataTitle}</label>
          <input
            onKeyDown={onKeyDown} onBlur={onBlur} onChange={onChange} name={name} value={value} id={id} type={type} 
            
            className="focus:ring-indigo-500 focus:border-indigo-500 w-full pl-9 pr-32 pt-4 pb-4 sm:text-sm  border-none rounded-xl"
            // placeholder={dataTitle}
          />


          {/*************************************   DENOMINATION INPUT       **************************************/}
          {denominations ? (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <select
            onChange={onChange} name={dName} value={dValue} id={dId} datatype={dType} placeholder={dPlaceholder}
            //   id="currency"
            //   name="currency"
              className="focus:ring-indigo-500 focus:border-indigo-500 h-full pl-2 pr-8 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                {denominations?.map(denomination => {
                    return( <option>{denomination}</option> ) 
                    })}
            </select>
          </div> ) :
          (<> </>) }

        </div>
      </div>
    )
  }

  export default NumberInputInUnits