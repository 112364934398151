import React, { useState, useEffect} from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon, Popup, Marker, LayerGroup, LayersControl, } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import {InputSelection} from "../../../types/ui";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import FormCard from "UI/components/shared/FormCard";
import FormTwoInputs from "UI/components/shared/FormTwoInputs";
import LargeTextInput from "UI/components/shared/LargeTextInput";
import NumberInputInUnits from "UI/components/shared/NumberInputInUnits";
import { getCrops } from "features/crops/cropSlice";
import { addField } from "features/fields/fieldSlice";
import { getFields } from "features/fields/fieldSlice";
import { addInfrastructure, getInfrastructures } from "features/infrastructure/infrastructureSlice";
import { RootState } from "../../../app/store";
import {toast} from "react-toastify";
import { t } from "i18next";

const FieldHomePageLeafletPolygon = () => {

  const ref1 = useRef(null);
  const doClick = () => ref1.current?.scrollIntoView({behavior: 'smooth'})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation(["field", "translation"], ["infrastructure", "translation"])


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(getFields())
  }, [dispatch])


  const {crops} = useSelector((state) => state.crop )
  const {fields, status} = useSelector((state) => state.field)


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    USE STATE - FIELD   //////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON CHANGE   //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON SUBMIT   //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  
  
  const [center, setCenter] = useState({ lat: 36.985206, lng: -120.292997 });
  const [mapLayers, setMapLayers] = useState([]);
  const [polygon1, setPolygon] = useState([])

  const ZOOM_LEVEL = 15;
  const mapRef = useRef();

  let polygonCoordinates = []

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON CREATE MAP OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const _onCreate = (e) => {
    doClick()

    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }

    let coordinateArray = layer.getLatLngs()[0]
    if (coordinateArray.length !== 0) {
      coordinateArray.map( (coordinate) =>  {
        let singleArray = []
        singleArray.push(coordinate.lat)
        singleArray.push(coordinate.lng)
        polygonCoordinates.push(singleArray)
        setPolygon((coords) => [
          ...coords,
          singleArray
        ] )
      })
    }
  };


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON EDIT MAP OBJECT   /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const _onEdited = (e) => { 
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) => 
        
        l.id === _leaflet_id
          ? { ...l, latlngs: { ...editing.latlngs[0] } }
          : l
        )
      );
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON DELETE MAP OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const _onDeleted = (e) => {  
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    MAP OBJECT OPTIONS   /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const purpleOptions = { color: 'purple' }
  const redOptions = { color: 'blue', weight: 1, dashArray: 'polygon', opacity: 0.8, fill: true, fillOpacity: 0.1 }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    RETURN OF THE OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="" >
        <div className="" >

          {/* //////////////////////////////////////////////////////////////////////////// */}
          {/* //////////////////////////   MAP DISPLAY   ///////////////////////////////// */}
          {/* //////////////////////////////////////////////////////////////////////////// */}

          <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>

            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>


            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>

            {/* <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}
            
            <TileLayer url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom= {20}/>

            <LayersControl position="topright">
              <LayersControl.Overlay name="Fields">
                <LayerGroup>
                  {fields?.map(((field) => (
                      <Polygon pathOptions={redOptions} positions={field?.latlngs}>
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable. Field ID
                          <h1>{field?._id}</h1>
                          <h1>{field?.description}</h1>
                        </Popup>
                      </Polygon>
                  ) ))}
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
            <LeafletControlGeocoder/>
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default FieldHomePageLeafletPolygon;