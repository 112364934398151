import React from 'react'
import {Link} from 'react-router-dom'
import {PageId} from "../../../types/pages";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import FieldTable from './FieldTable';
import FieldTasks from './FieldTasks';
import FieldActivity from './FieldActivity';
import FieldDashboard from './FieldDashboard';
import FieldHomePageLeafletPolygon from '../Map/FieldHomePageLeafletPolygon';

function Field() {
    const {t} = useTranslation(["yield", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.LiveStock);

    return (
        <>
        <div className='px-5'>
        <div className='pb-10' >
            <FieldHomePageLeafletPolygon/>
        </div>

            <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
                <ul className="flex felx-row justify-left cursor-pointer">
                    <li onClick={() => toggleTab(1)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 1 ? 'bg-indigo-500 text-white' : ''}`}>{t("field:home")}
                    </li>
                    <li onClick={() => toggleTab(2)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 2 ? 'bg-indigo-500 text-white' : ''}`}>{t("field:fields")}
                    </li>
                    <li onClick={() => toggleTab(3)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 3 ? 'bg-indigo-500 text-white' : ''}`}>{t("field:tasks")}
                    </li>
                    <li onClick={() => toggleTab(4)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 4 ? 'bg-indigo-500 text-white' : ''}`}>{t("field:activity")}
                    </li>
                </ul>

                {selection === 1 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <FieldDashboard/>
                    </div>
                ) : (<></>)}

                {selection === 2 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <FieldTable/>
                    </div>
                ) : (<></>)}

                {selection === 3 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <FieldTasks/>
                        <Link className="btn  mx-9" to={"/task-form"}>
                            Add Task
                        </Link>

                    </div>
                ) : (<></>)}

                {selection === 4 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>

                        <FieldActivity/>
                        <Link className="btn mx-9 mt-6" to={"/activity-form"}>
                            Add Activity
                        </Link>
                    </div>
                ) : (<></>)}

            </div>


            <Link className="btn mb-8" to={"/field-form"}>
                Add Field
            </Link>
        </div>
        </>
    )
}

export default Field