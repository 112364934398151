import { ActivityModel } from '@asirisos/types'
import { Link } from 'react-router-dom'
import React from 'react'

function ChangeActivityList(props : {allActivity: ActivityModel[], page : String}) {
  return (
    <>
        <div className="bg-white py-5 mt-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
                    <h1 className='py-4 font-semibold text-xl ' > {`${props.page} Activity`} </h1>
                {props.allActivity.map((activity) => (
                <>
                    <div className="bg-white mt-3 py-2 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
                        <div className='absolute top-3 left-3 round ' >
                            <div className='bg-indigo-400 w-1 h-1 p-2 rounded-full'>

                            </div>
                        </div>
                        <div className='flex'>


                            {/* ACCESS ARRAY CHANGES */}
                            {activity?.changedArrayAdded !== undefined && activity?.changedArrayRemoved !== undefined && ( activity?.changedArrayAdded?.length !== 0 || activity?.changedArrayRemoved?.length !== 0 ) ? (<>
                                <h1 className='font-bold mr-1'> {`${activity?.changedField}`}</h1>
                                <h1 className='mr-1' > has been changed to </h1>

                                {activity?.changedArrayAdded?.length !== 0 ? (<>
                                    <h1> include </h1>
                                    <h1 className='font-bold mx-2'> {activity?.changedArrayAdded?.map((item) => (' ' +item + '  ')  )}</h1>
                                    
                                </>) : (<></>) }

                                {activity?.changedArrayAdded?.length !== 0 && activity?.changedArrayRemoved?.length !== 0 ? (<>
                                    <h1 className='mx-1' > and </h1>
                                    
                                </>) : (<></>) }


                                {activity?.changedArrayRemoved?.length !== 0 ? (<>
                                    <h1> romove </h1>
                                    <h1 className='font-bold mx-2'> {activity?.changedArrayRemoved?.map((item) => (' ' + item + '  ') )}</h1>
                                    
                                </>) : (<></>) }

                                <h1> by </h1> 
                                <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                                    <h1 className='font-bold ml-1 '>{activity?.assignedToObject?.name}</h1>
                                </Link>

                            </>) : (<> 
                                {/* <h1 className='font-bold mr-1'> {`${activity?.changedField}`}</h1>
                                <h1> has been changed from</h1>
                                <h1 className='font-bold mx-1'>{activity?.changedFieldBefore}</h1> 
                                <h1> to </h1> 
                                <h1 className='font-bold mx-1 '>{activity?.changedFieldAfter}</h1>
                                <h1> by </h1> 
                                <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                                    <h1 className='font-bold ml-1 '>{activity?.assignedToObject?.name}</h1>
                                </Link>  */}
                            </>) }

                            {activity?.activityType === 'change' && ( activity?.changedArrayAdded?.length === 0 && activity?.changedArrayRemoved?.length === 0 ) ? (<>
                                <h1 className='font-bold mr-1'> {`${activity?.changedField}`}</h1>
                                <h1> has been changed from</h1>
                                <h1 className='font-bold mx-1'>{activity?.changedFieldBefore}</h1> 
                                <h1> to </h1> 
                                <h1 className='font-bold mx-1 '>{activity?.changedFieldAfter}</h1>
                                <h1> by </h1> 
                                <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                                    <h1 className='font-bold ml-1 '>{activity?.assignedToObject?.name}</h1>
                                </Link> 
                            </>) : (<></>)}

                            {activity?.activityType === 'add' ? (<>
                                <Link to={`/employee/${activity?.assignedToObject?._id}`} >
                                    <h1 className='font-bold mr-1 '>{`${activity?.assignedToObject?.name }`}</h1>

                                </Link> 
                                <h1>created this item</h1>
                                {/* <h1 className='font-bold mr-1'> {`${activity?.changedField}`}</h1> */}
                            </>) : (<></>)}                            
                        </div>

                        <div className='flex justify-end' >
                            <h1>{activity?.createdAt?.substr(0, 10)}</h1>
                            <h1 className='mx-2' >{activity?.createdAt?.substr(11, 5)}</h1>
                        </div>
                    </div>
                </>
            ) )}
                </div>
    </>
  )
}

export default ChangeActivityList