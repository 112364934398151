import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import './i18n';
import axios from 'axios'
import Spinner from "./UI/components/Spinner";
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.timeout = 5000

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(

   // This is needed to allow certain components (i.e. n18next) to do some lazy-loading
    <React.Suspense fallback={<Spinner/>}>
        {/*This would make some pages load twice*/}
        {/*It should only be happening in dev environments*/}
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    </React.Suspense>
);
