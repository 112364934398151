import {AnyAction, combineReducers, configureStore, PreloadedState} from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import cropReducer from '../features/crops/cropSlice'
import testReducer from '../features/tests/testSlice'
import equipmentReducer from '../features/equipment/equipmentSlice'
import farmReducer from '../features/farms/farmSlice'
import taskTaskReducer from '../features/task/taskSlice'
import activityReducer from '../features/activity/activitySlice'
import inventoryReducer from '../features/inventory/inventorySlice'
import customerReducer from '../features/customers/customerSlice'
import saleReducer from '../features/sales/saleSlice'
import farmJoinReducer from '../features/farm-join/farm-joinSlice'
import accountReducer from '../features/accounts/accountSlice'
import liveStockReducer from '../features/live-stock/live-stockSlice'
import expenseReducer from '../features/expenses/expenseSlice'
import storageReducer from '../features/storage/storageSlice'
import commentReducer from '../features/comments/commentSlice'
import fieldReducer from '../features/fields/fieldSlice'
import infrastructureReducer from '../features/infrastructure/infrastructureSlice'
import organizationReducer from '../features/organizations/organizationSlice'
import utilsReducer from "../features/utils/utilsSlice";
import yieldReducer from "../features/yield/yieldSlice"
import timeSeriesDataReducer from "../features/timeSeriesData/timeSeriesDataSlice"
import orgLocationReducer from "../features/orgLocations/orgLocationsSlice"


const rootReducer = combineReducers({
  auth: authReducer,
  crop: cropReducer,
  comment: commentReducer,
  test: testReducer,
  equipment: equipmentReducer,
  farm: farmReducer,
  field: fieldReducer,
  yield: yieldReducer,
  timeSeriesData: timeSeriesDataReducer,
  infrastructure: infrastructureReducer,
  organization: organizationReducer,
  orgLocation: orgLocationReducer,
  taskTask: taskTaskReducer,
  activity: activityReducer,
  inventory: inventoryReducer,
  customer: customerReducer,
  sale: saleReducer,
  expense: expenseReducer,
  livestock: liveStockReducer,
  farmJoin: farmJoinReducer,
  account: accountReducer,
  storage: storageReducer,
  utils: utilsReducer
})

export const store = configureStore({
  reducer: rootReducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch