import React from 'react'
import {FaSignInAlt, FaSignOutAlt, FaUser} from 'react-icons/fa'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {logout, reset} from '../../features/auth/authSlice'
import {reset as resetAccounts} from '../../features/accounts/accountSlice'
import {AppDispatch, RootState, store} from "../../app/store";
import {useAuthStatus} from "../../hooks/useAuthStatus";

function Header() {

    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const {loggedIn} = useAuthStatus()


    const onLogout = () => {
        localStorage.clear()
        navigate('/login')
        dispatch(reset())
        dispatch(resetAccounts())
    }

  return (
    <>
    {loggedIn ? (<>
    <header className="header">
        <div className="logo">
            <Link to='/home'>AsirisOS</Link>
        </div>
        <ul>
            {loggedIn ? (
                <>
                <li>
                    <button className="btn" onClick={onLogout} > <FaSignOutAlt/>Logout </button>
                </li>
                <Link className="btn ml-2" to={"/profile"} >
                    Profile
                </Link>
                </>
            ) : (
                <>
            <li>
                <Link to='/login' >
                    <FaSignInAlt /> Login
                </Link>
            </li>
            <li>
                <Link to='/register' >
                    <FaUser /> Register
                </Link>
            </li>
            </>
            ) }
            
        </ul>
    </header>
    
    </>) : (<></>) }


    </>
  )
}

export default Header