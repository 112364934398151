import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import { deleteExpense, getExpense, updateExpense } from 'features/expenses/expenseSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {ExpenseModel} from "@asirisos/types";
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'
import ChangeActivityList from '../Activity/ChangeActivityList'

function ExpensePage() {
    const {t} = useTranslation("expense");
    const navigate = useNavigate()

    const categorySelection = [
        {name: t("expense:general"), id: 'general'},
        {name: t("expense:crop"), id: 'crop'},
        {name: t("expense:equipment"), id: 'equipment'},
        {name: t("expense:services"), id: 'services'}]

        

    const {expenseId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {expense, status} = useSelector((state: RootState) => state.expense)
    const {allActivity} = useSelector((state: RootState) => state.activity)


    ///////////// CHANGE ACTIVITY
    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData

    let changedField : string

    //////////////////////////

    useEffect(() => {
        if (expenseId)
            dispatch(getExpense(expenseId))
            dispatch(getActivityByConnectionId(expenseId!))
    }, [dispatch, expenseId])


    console.log('expense',expense)


    if (status === Status.Failure) {
        toast.error(t("expense:expenseGetFailed"))
    }

    const [formData, setFormData] = useState({
        customer: '',
        category: '',
        item: '',
        quantity: '',
        expenseStatus: '',
        comments: '',
    })

    useEffect(() => {
        if (status !== Status.Pending && expense !== undefined) setFormData((prevState) => ({
            ...prevState,
            customer: expense.customer,
            category: expense.category,
            item: expense.item,
            quantity: expense.quantity,
            expenseStatus: expense.expenseStatus,
            comments: expense.comments,
        }));
    }, [status])

    const {customer, category, item, quantity, expenseStatus, comments} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        console.log(e.target)
        console.log(e)
        console.log('name', e.target.name)
        console.log('value', e.target.value)

        setChangedData(() => ({
            field: e.target.name 
        }))

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        console.log('handle Change', e.target.name, e.target.value)
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const expenseData: ExpenseModel = {
            // type: e.target.value,
            customer,
            category: e.target.value,
            item,
            quantity,
            expenseStatus,
            comments,
            _id:expenseId,
            changedField: e.target.id,
        }

        dispatch(updateExpense(expenseData))
            .unwrap()
            .then(() => {
                toast.success(t("expense:expenseUpdated"))
            })
            .catch(() => {
                toast.error(t("expense:expenseUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const expenseData : ExpenseModel = {
            customer,
            category,
            item,
            quantity,
            expenseStatus,
            comments,
            _id:expenseId,
            changedField: changedData.field,
        }

        dispatch(updateExpense(expenseData))
            .unwrap()
            .then(() => {
                toast.success(t("expense:expenseUpdated"))
            })
            .catch(() => {
                toast.error(t("expense:expenseUpdateFailed"))
            })
    }


    // DELETE FUNCTIONALITY

    const onDelete = () => {
        dispatch(deleteExpense([expenseId!]))
        .unwrap()
        .then(() => {
            toast.success(t("expense:expenseDeleted"));
            navigate("/expenses")
        })
        .catch(() => {
            toast.error(t("expense:failedToDeleteexpense"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }



    if (status === Status.Pending && expense?.customer != '' && expense?.comments != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div
                    className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:customer")} value={customer} id={"customer"}
                                        name={"customer"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:quantity")} value={quantity} id={"quantity"}
                                        name={"quantity"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:comments")} value={comments} id={"comments"}
                                        name={"comments"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)}
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={category} id={"category"} title={"category"} name={t("translation:category")}/>
                        </div>
                    </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                <ItemPageDeleteButton onDeleteClick={onDeleteClick} />

                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={expense?.quantity!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Expenses'} />
            </>
        )
    }
}


export default ExpensePage