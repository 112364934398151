import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getCrops } from '../../../features/crops/cropSlice'
import { getStorage, updateStorage, deleteStorage, reset } from '../../../features/storage/storageSlice'
import {toast} from 'react-toastify'
import { StorageModel } from '@asirisos/types'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import ChangeActivityList from '../Activity/ChangeActivityList'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import DeleteModal from 'UI/components/DeleteModal'
import Modal from 'react-modal'
import { InputSelection } from 'types/ui'
import {Status} from "../../../types/slice";
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'


function StoragePage() {

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    DROP DOWN SELECTIONS   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const categorySelections = [
  new InputSelection('crop', 'crop'),
  new InputSelection('live stock', 'live stock')]


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    SET UP - DISPATCH   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const navigate = useNavigate()
const dispatch = useDispatch<AppDispatch>()
const {t} = useTranslation("inventory")


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    DROP DOWN CROP SELECTION   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    dispatch(getCrops())
},[dispatch] )


const {crops} = useSelector((state: RootState) => state.crop )
const {allActivity} = useSelector((state: RootState) => state.activity)


const cropSelections = crops?.map((crop) => {
    console.log('printing crops', crop.cropName, crop._id)
    return {name: crop.cropName, id:crop._id!}
})


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    PARAMS TO GET THE ID   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const params = useParams()
  const {storageId} = useParams()


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE EFFECT (GET EQUIPMENT)   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        if(status === Status.Failure) {
            toast.error(message)
        }
        if(storageId)
          dispatch(getStorage(storageId))
          dispatch(getActivityByConnectionId(storageId!))
    }, [dispatch, storageId] )

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {storage, status, message } = useSelector((state : RootState) => state.storage )

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE STATE -- INITIALIZE FORM DATA  ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const [formData, setFormData] = useState({
  name: '',
  description: '',
  category: '',
  quantity: 0,
  comments: '',
  crop: ''
})

//////////// CHANGED DATA STATE ///////////

const [changedData, setChangedData] = useState({
  field: ''
})

const {field} = changedData
let changedField : string



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE EFFECT -- SET FORM DATA WHEN THE STATE CHANGED   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      useEffect(()=>{
        if(status !== Status.Pending && storage !== undefined) setFormData( (prevState) => ({
          ...prevState,
          name: storage.name,
          description: storage.description,
          quantity: storage.quantity,
          category: storage.category, 
          crop: storage.crop!,
          comments: storage.comments
        }));
        },[status])

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    DESTRUCTURE FORM DATA   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {  name, description,  quantity, category, crop, comments} = formData


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    ON CHANGE - SET FORM DATA WHEN INPUT FIELD CHANGED   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setChangedData(() => ({
        field: e.target.name 
      }))
      
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
      } ))

    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    HANDLE CHANGE - SET FORM DATA AND DISPATCH UPDATE WHEN DROPDOWN INPUT FIELD CHANGED   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChnage = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    } ))
    const storageData : StorageModel = {
      name, 
      description,  
      // liveStock,
      quantity, 
      category: e.target.value, 
      crop: e.target.value, 
      comments,
      _id: storageId,
      changedField: e.target.id
  }
  
    dispatch(updateStorage(storageData))
      .unwrap()
      .then(() => {
          toast.success(t("storage:storageUpdated"))
      })
      .catch(() =>{
          toast.error(t("storage:storageUpdateFailed"))
      })
    }
    
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    ON KEY DOWN - SET BLUR TO START UPDATE DISPATCH WHEN CLICK AWAY   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "Escape") {
        console.log('Key Down Hit');
        (e.target as HTMLElement).blur();
      }
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    ON BLUR - DISPATCH UPDATE WHEN CLICKED INPUT FIELD   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const onBlur = (e: React.FocusEvent) => {
      e.preventDefault()
      console.log('On Blur Hit')
      const storageData = {
        name, 
        description,  
        quantity, 
        category, 
        crop, 
        comments,
        _id: storageId,
        changedField: changedData.field
    }
    dispatch(updateStorage(storageData))
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    HANDLE DELETE - DISPATCH DELETE   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////
    
    const onDelete = () => {
      dispatch(deleteStorage([storageId!]))
      .unwrap()
      .then(() => {
          toast.success(t("storage:storageDeleted"));
          navigate("/storage")
      })
      .catch(() => {
          toast.error(t("storage:failedToDeletestorage"))
      })
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const onDeleteClick = (e: any) => {
      setModalIsOpen(!modalIsOpen)
  }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////   LOADING   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (status === Status.Pending && storage?.name != '') {
      return<Spinner/>
  } 

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////   FINAL PAGE RETURN   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  else {
  return (
    <>
    {/* /////////////////////////////////// NEW EQUIPMENT PAGE - WORKING ON THIS ////////////////////////////////////////// */}

    <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  " >
      
      {/* <div className='position: absolute top-2  right-2 ' >
          <h1 className='text-md text-right mr-3 pt-2 font-thin ' >{`Edit`}</h1>
      </div> */}
      
    <div className="grid grid-cols-3 gap-3">
      <div className='-my-1 col-span-1 justify-self-center self-center'>
        <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl" src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
      </div>
      <div className='col-span2 justify-start justify-self-start' >
          <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"name"} value={name} id={"name"} name={"name"} />
          <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"description"} value={description} id={"description"} name={"description"} />
          <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"quantity"} value={quantity.toString()} id={"quantity"} name={"quantity"} />
          <InlineEditDropDown onChange={(e) => handleChnage(e)} onKeyDown={(e) => onKeyDown(e) } selections={categorySelections}  value={category} id={"category"} title={"category"} name={"category"}  />
          <InlineEditDropDown onChange={(e) => handleChnage(e)} onKeyDown={(e) => onKeyDown(e) } selections={cropSelections}  value={crop} id={"crop"} title={"crop"} name={"crop"}  />
      </div>
      </div>
      {/* ////////////////////////////////////////////////////////////////////// */}
      {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

      <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
    </div>

    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

    <DeleteModal modalIsOpen={modalIsOpen} name={storage?.name!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
    
    <ChangeActivityList allActivity={allActivity} page={'Storage'} />
    </>
  ) }
}


export default StoragePage