import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import {getCustomer, updateCustomer, deleteCustomer, reset} from '../../../features/customers/customerSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {CustomerModel} from "@asirisos/types";
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import ChangeActivityList from '../Activity/ChangeActivityList'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'


function CustomerPage() {


    const {t} = useTranslation("customer");
    const {customerId} = useParams()
    const navigate = useNavigate()

    const dispatch = useDispatch<AppDispatch>()
    const {customer, status} = useSelector((state: RootState) => state.customer)
    const {allActivity} = useSelector((state: RootState) => state.activity)


    useEffect(() => {
        if (customerId !== undefined)
            dispatch(getCustomer(customerId))
            dispatch(getActivityByConnectionId(customerId!))
    }, [dispatch, customerId])

    if (status === Status.Failure) {
        toast.error(t("equipment:equipmentGetFailed"))
    }


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    })

    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData
    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && customer !== undefined) setFormData((prevState) => ({
            ...prevState,
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            phone: customer.phone
        }));
    }, [status])

    const {firstName, lastName, email, phone} = formData

    const onChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        changedField = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const customerData: CustomerModel = {
            firstName,
            lastName,
            email,
            phone,
            _id:customerId,
            changedField: e.target.id
        }

        dispatch(updateCustomer(customerData))
            .unwrap()
            .then(() => {
                toast.success(t("customer:customerUpdated"))
            })
            .catch(() =>{
                toast.error(t("customer:customerUpdateFailed"))
            })
    }



    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }


    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const customerData: CustomerModel = {
            firstName,
            lastName,
            email,
            phone,
            _id:customerId,
            changedField: changedData.field
        }
        dispatch(updateCustomer(customerData))
            .unwrap()
            .then(() => {
                toast.success(t("customer:customerUpdated"))
            })
            .catch(() => {
                toast.error(t("customer:customerUpdateFailed"))
            })
    }

    // DELETE FUNCTIONALITY AND MODAL STATE
    const onDelete = () => {
        dispatch(deleteCustomer([customerId!]))
        .unwrap()
        .then(() => {
            toast.success(t("customer:customerDeleted"));
            navigate("/customers")
        })
        .catch(() => {
            toast.error(t("customer:failedToDeletecustomer"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }


    if (status === Status.Pending && customer?.firstName != '') {
        return <Spinner/>
    } else {
        return (
            <>

                <div
                    className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/6009/6009864.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"firstName"} value={firstName}
                                        id={"firstName"} name={"firstName"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"lastName"} value={lastName}
                                        id={"lastName"} name={"lastName"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"email"} value={email} id={"email"}
                                        name={"email"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"phone"} value={phone} id={"phone"}
                                        name={"phone"}/>
                        </div>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                    <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={customer?.firstName!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Customer'} />

                {/* /////////////////////////////////// NEW Customer PAGE ////////////////////////////////////////// */}


                {/* /////////////////////////////////// OLD Customer PAGE ////////////////////////////////////////// */}

                {/* <div className="flex flex-row justify-center">
    <div className="card w-96 bg-base-100 shadow-xl ">
        <div class="card-body">
            <h2 class="card-title justify-center">{Customer.name}</h2>
             <p>{Customer.milage}</p>
            <div class="card-actions justify-center">
            <button class="btn btn-primary" onClick={openModal}>Edit</button>
            <button class="btn btn-primary" onClick={handleDelete}>Delete</button>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Edit Customer">
            <Card>
          <div className="absolute -left-4 -top-4 bg-indigo-500 px-3 rounded-xl text-white pt-2 ">
          <h1>Add Customer</h1>
          <h1 onClick={closeModal}>Close</h1>
          </div>
          <form >
        <div className="space-y-7 pt-8">
          <div className="flex flex-row justify-around" >
            <input 
            type="text" 
            placeholder="Customer Name" 
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            class="input input-bordered w-full max-w-xs"/>

        <select 
            class="select select-bordered w-full max-w-xs"
            id="type"
            name="type"
            value={type}
            onChange={onChange}>
            <optgroup label="Type of Customer">
            <option value="general">general</option>
            <option value="crop">crop</option>
            <option value="livestock">livestock</option>
            <option value="seeding">seeding</option>
            <option value="harvesting">harvesting</option>
            </optgroup>
            </select>
            </div>

          <div className="flex flex-row justify-around" >
          <input 
          type="number" 
          placeholder="Milage"
          id="milage"
          name="milage"
          value={milage} 
          onChange={onChange}
          class="input input-bordered w-full max-w-xs"/>

          <input 
          type="number" 
          placeholder="VIN"
          id="vin"
          name="vin"
          value={vin} 
          onChange={onChange}
          class="input input-bordered w-full max-w-xs"/>
          </div>

          <div className="flex flex-row justify-around" >
          <input 
          type="text" 
          placeholder="farmId"
          id="farmId"
          name="farmId"
          value={farmId} 
          onChange={onChange}
          class="input input-bordered w-full max-w-xs"/>
        </div>
      </div>
      <div className='flex justify-center pt-8 p-2' >
      <button class="btn btn-wide btn-primar hover:bg-black">Update Customer</button>
      </div>
      </form>
      </Card>
            </Modal>
    </div>
  </div>
</div>
</div> */}
            </>
        )
    }
}


export default CustomerPage