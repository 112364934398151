import React, {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate} from 'react-router-dom'
import { deleteField, getField, updateField } from 'features/fields/fieldSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {Status} from "../../../types/slice";
import {FieldModel} from "@asirisos/types";
import ChangeActivityList from '../Activity/ChangeActivityList'
import DeleteModal from 'UI/components/DeleteModal'
import {InputSelection} from "../../../types/ui";
import Modal from 'react-modal'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import SingleFieldPageLeafletPolygon from '../Map/SingleFieldPageLeafletPolygon'

function FieldPage() {
    
    const {t} = useTranslation("field")
    const navigate = useNavigate()
    const {fieldId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {field, status} = useSelector((state: RootState) => state.field)
    const {allActivity} = useSelector((state: RootState) => state.activity)

    const categorySelection = [
        new InputSelection(t("field:categoryFeed"), 'feed'),
        new InputSelection(t("field:categoryChemicals"), 'chemicals'),
        new InputSelection(t("field:categoryFertilizerAndConditioners"), 'fertilizerAndConditioners'),
        new InputSelection(t("field:categoryFuel"), 'fuel'),
        new InputSelection(t("field:categoryFarmSupplies"), 'farmSupplies'),
        new InputSelection(t("field:categoryEquipmentParts"), 'equipmentParts'),
        new InputSelection(t("field:categorySeedsAndPlants"), 'seedsAndPlants'),
        new InputSelection(t("field:categoryEquipmentMaintenance"), 'equipmentMaintenance'),
        new InputSelection(t("field:categoryInfrastructureMaintenance"), 'infrastructureMaintenance'),
        new InputSelection(t("field:categoryLivestockMaintenance"), 'livestockMaintenance'),
        new InputSelection(t("field:categoryOther"), 'other'),]

    useEffect(() => {
        if (fieldId) {
            dispatch(getField(fieldId))
            dispatch(getActivityByConnectionId(fieldId!)) }
    }, [dispatch, fieldId])

    if (status === Status.Failure) {
        toast.error(t("field:fieldGetFailed"))
    }

    const [formData, setFormData] = useState({
        farmId: '',
        coordinates: [[]] ,
        connectionCategory: '',
        assetValue: '',
        description: '',
        fieldCategory: '',
        fieldName: '',
        employee: '',
        deadline: '',
        equipment:'',
        crop: '',
        liveStock: '',
        inventory: '',
        storage: '',
        customer: '',
        sale: '',
        expense: '',
        fieldType: '',
        latlngs: '',
        mapSection: '',
    })

    const {latlngs, mapSection, farmId, coordinates , connectionCategory, assetValue, description, fieldName, employee, deadline, 
        equipment, crop, liveStock, inventory, storage, customer, sale, expense, fieldType, fieldCategory} = formData


    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        updatedField: ''
    })

    const {updatedField} = changedData
    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && field !== undefined)
            dispatch(getActivityByConnectionId(fieldId!))
    }, [fieldId, status])

    useEffect(() => {
        if (status !== Status.Pending && field !== undefined) setFormData((prevState) => ({
            ...prevState,
            latlngs: field.latlngs,
            mapSection: field.mapSection,
            farmId: field.farmId,
            // coordinates : field.coordinates,
            connectionCategory: field.connectionCategory,
            assetValue: field.assetValue,
            description: field.description,
            fieldName: field.fieldName,
            employee: field.employee,
            deadline: field.deadline,
            equipment: field.equipment,
            crop: field.crop,
            liveStock: field.liveStock,
            inventory: field.inventory,
            storage: field.storage,
            customer: field.customer,
            sale: field.sale,
            expense: field.expense,
            fieldCategory: field.fieldCategory

            // fieldType: field.fieldType,
        }));
    }, [status])

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            updatedField: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        changedField = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const fieldData: FieldModel = {
            _id: fieldId,
            farmId,
            coordinates ,
            connectionCategory,
            assetValue,
            description,
            fieldCategory,
            fieldName,
            employee,
            deadline,
            equipment,
            crop,
            liveStock,
            inventory,
            storage,
            customer,
            sale,
            expense,
            latlngs,
            mapSection,

            
            // type: e.target.value, 
            // changedField: e.target.id
        }

        dispatch(updateField(fieldData))
            .unwrap()
            .then(() => {
                toast.success(t("field:fieldUpdated"))
            })
            .catch(() =>{
                toast.error(t("field:fieldUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        const fieldData: FieldModel = {
            _id: fieldId,
            farmId,
            coordinates ,
            connectionCategory,
            assetValue,
            description,
            fieldCategory,
            fieldName,
            employee,
            deadline,
            equipment,
            crop,
            liveStock,
            inventory,
            storage,
            customer,
            sale,
            expense,
            latlngs,
            mapSection,
            // changedField: changedData.field
        }

        dispatch(updateField(fieldData))
            .unwrap()
            .then(() => {
                toast.success(t("field:fieldUpdated"))
            })
            .catch(() =>{
                toast.error(t("field:fieldUpdateFailed"))
            })
    }


    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////

    const onDelete = () => {
        dispatch(deleteField([fieldId!]))
        .unwrap()
        .then(() => {
            toast.success(t("field:fieldDeleted"));
            navigate("/field")
        })
        .catch(() => {
            toast.error(t("field:failedToDeletefield"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


    if (status === Status.Pending && field?.fieldName != '' && field?.fieldCategory != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <>

                <div className='pb-10' >
                    <SingleFieldPageLeafletPolygon field={field}/>
                </div>
                </>
                <div
                    className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            {/* <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:title")} value={title} id={"title"}
                                        name={"title"}/> */}
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:description")} value={description}
                                        id={"description"} name={"description"}/>
                            {/* <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:totalArea")} value={totalArea.toString()}
                                        id={"totalArea"} name={"totalArea"}/> */}
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("yield:value")} value={description} id={"description"}
                                        name={"description"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)} 
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={connectionCategory} id={"connectionCategory"} title={t("translation:connectionCategory")} 
                                                name={t("translation:connectionCategory")}/>
                        </div>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                    <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={field?.fieldName!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Field'} />
                
            </>
        )
    }
}


export default FieldPage