import React, { useState } from "react";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Button from "@material-ui/core/Button";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";
const params = {
  q: "",
  format: "json",
  addressdetails: "addressdetails",
};

export default function SearchBox(props) {
  const { selectPosition, setSelectPosition, onSetAddress } = props;
  const [searchText, setSearchText] = useState("");
  const [listPlace, setListPlace] = useState([]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }} className=" ">
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <div className="shadow-md flex flex-row rounded-xl rounded-r-none mr-8" >
          <input className="focus:ring-indigo-500 py-4 focus:border-indigo-500 w-full pl-9 pr-32 mt-3 pb-4 sm:text-sm  border-none rounded-xl rounded-r-none"
          onKeyDown = {(e) => {
            // console.log('onkeydown - e',e)
            if (e.key === "Enter" || e.key === "Escape") {
              console.log('On KeyDown Enter', e.target.value)
                // (e.target).blur();
                const params = {
                  q: searchText,
                  format: "json",
                  addressdetails: 1,
                  polygon_geojson: 0,
                };
                console.log('search text',searchText)
                const queryString = new URLSearchParams(params).toString();
                const requestOptions = {
                  method: "GET",
                  redirect: "follow",
                };
                fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                  .then((response) => response.text())
                  .then((result) => {
                    console.log(JSON.parse(result));
                    setListPlace(JSON.parse(result));
                  })
                  .catch((err) => console.log("err: ", err));
                e.preventDefault()
            }

            }}
            style={{ width: "100%" }}
            value={searchText}
            name="searchText"
            onChange={(event) => {
              setSearchText(event.target.value);
              const params = {
                q: searchText,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
              };
              console.log('search text',searchText)
              const queryString = new URLSearchParams(params).toString();
              const requestOptions = {
                method: "GET",
                redirect: "follow",
              };
              fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  console.log(JSON.parse(result));
                  setListPlace(JSON.parse(result));
                })
                .catch((err) => console.log("err: ", err));
            }}
          /> 
          <button
            className="p-3 mt-3 bg-indigo-400 text-white rounded-l-none rounded-md hover:bg-indigo-600 " 
            onClick={(e) => {
              // Search
              const params = {
                q: searchText,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
              };
              console.log('search text',searchText)
              const queryString = new URLSearchParams(params).toString();
              const requestOptions = {
                method: "GET",
                redirect: "follow",
              };
              fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  console.log(JSON.parse(result));
                  setListPlace(JSON.parse(result));
                })
                .catch((err) => console.log("err: ", err));
                e.preventDefault()
              }} >    Search </button>
          </div>
        </div>

        {/*//////////////////////////////////////////////////////////////////////////// */}
        {/*////////////////////////// SEARCH BUTTON     /////////////////////////////// */}
        {/*//////////////////////////////////////////////////////////////////////////// */}

        {/* <div className="flex py-4" 
        // style={{ display: "flex", alignItems: "center", padding: "0px 20px" }}
        >
          <button
            className="p-3 mt-3 bg-green-500 rounded-md"
            onClick={() => {
              // Search
              const params = {
                q: searchText,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
              };
              console.log('search text',searchText)
              const queryString = new URLSearchParams(params).toString();
              const requestOptions = {
                method: "GET",
                redirect: "follow",
              };
              fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  console.log(JSON.parse(result));
                  setListPlace(JSON.parse(result));
                })
                .catch((err) => console.log("err: ", err));
              }} >    Search </button>
        </div> */}
      </div>


      {/*//////////////////////////////////////////////////////////////////////////// */}
      {/*////////////////////////// START OF THE LIST /////////////////////////////// */}
      {/*//////////////////////////////////////////////////////////////////////////// */}
      
      <div className="flex flex-col mr-8 rounded-lg border-2" >
        {/* <List component="nav" aria-label="main mailbox folders"> */}
        {/* {selectPosition?.place_id === 0 ?(<> */}
          {listPlace.map((item) => 
            (
              <div className="border-b-2 hover:border-2 hover:border-indigo-500 hover:shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] " key={item?.place_id}>
                <button className="hover:border-indigo-500"
                  // onChange={props.onChange}
                  // value={props.value}
                  // name={props.name}
                  onClick={() => {
                    setSearchText(item?.display_name)
                    setListPlace([])
                    setSelectPosition(item);
                    onSetAddress(item)
                    console.log("SearchBox select position", selectPosition)
                    console.log("SearchBox select position type", typeof(selectPosition))
                    console.log("SearchBox list place", listPlace)
                    console.log("SearchBox search text", searchText)
                    console.log('returned display name of selected address', item.display_name)
                  }} >
                  <p className="my-1">{item?.display_name} </p>
                </button>
                <div className="" ></div>
              </div>
            )
            )}
          
        {/* </List> */}
      </div>
    </div>
  );
}


{/* <ListItemIcon>
  <img
    src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png"
    alt="Placeholder"
    style={{ width: 38, height: 38 }}
  />
</ListItemIcon> */}