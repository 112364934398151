import React from 'react'
import SaleTable from './SaleTable'
import { Link } from 'react-router-dom'

function Sales() {
  return (
    <>
    <div className='px-5' >
        <SaleTable/>
        <Link className="btn mb-8" to={"/sale-form"} >
        Add Sale
        </Link>
    </div>
    </>
  )
}

export default Sales