import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Spinner from '../../components/Spinner'
import {useParams, useNavigate} from 'react-router-dom'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import { addTimeSeriesDataPoint } from '../../../features/timeSeriesData/timeSeriesDataSlice';
import {Status} from "../../../types/slice";
import FormTwoInputs from 'UI/components/shared/FormTwoInputs';
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
import {toast} from 'react-toastify'
import { DataTopicModel, TimeSeriesDataModel } from '@asirisos/types';

function DataChart(props: {chartData: DataTopicModel }) {

    const {t} = useTranslation("data");
    const dispatch = useDispatch<AppDispatch>()
    const params = useParams()
    const [formStep, setFormStep] = useState(0)


    let chartData : any
    chartData = props.chartData?.timeSeriesData?.map(data => {
      return {
        data: data.data,
        createdAt: data.createdAt?.substr(5, 5)
        // activity?.createdAt?.substr(11, 5)
      }
    })

    const [formData, setFormData] = useState({
        data: 0,
    })

    const {data} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
       
        const timeSeriesData : TimeSeriesDataModel = {
            data,
            dataTopicId: props.chartData?._id
        }

        // console.log('Live Stock Weight Data',liveStockWeightData)

        dispatch(addTimeSeriesDataPoint(timeSeriesData))
            .unwrap()
            .then(() => {
                toast.success("data:DataAdded");
            })
            .catch(() => {
                console.log('get rejected')
                toast.error("data:FailedToAddData")
            })
        }

  return (
    <>
        <div className='flex flex-row justify-center' >
            <div className="grid grid-cols-4 gap-4 pt-1 pb-10 w-1/2  " >
                <div className='rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] col-span-4 bg-white #justify-self-center #self-center' >
                    <div className='self-center'>
                        <h1 className='text-center pl-3 pt-4 '>{props.chartData?.dataTopic}</h1>
                    </div>
                    <ResponsiveContainer  height="85%" >
                        <AreaChart className='bg-white #justify-self-center #self-center' data={chartData}
                            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="createdAt" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="data" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                            <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div>
                {/* <button className='bg-indigo-500 text-white px-3 py-2 mt-2'>Add Data</button> */}

                { formStep === 0 ? (
                    <>
                    <div className='flex flex-row justify-center p-2' >
                        <button onClick={() => {setFormStep(1)}} className=" shadow-lg text-lg border-indigo-500 border-2  py-1 px-2 rounded-md bg-white text-slate-700  hover:bg-indigo-500 hover:text-white ">Add Data</button>
                    </div>
                    </>
                ) : (
                    <>
                    <div className={`flex flex-row justify-center ml-10 mt-3`} >
                        <div className='relative pt-3 shadow-2xl rounded-md  bg-gray-100 #shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] ' >
                            <div className='text-2xl' >Add Data</div>
                            <div className='absolute -right-3 -top-4 p-2 ' >
                                <button onClick={() => {setFormStep(0)}} className="bg-indigo-500 py-2 px-3 text-white rounded-xl hover:bg-black">X</button>
                            </div>
                            <form onSubmit={onSubmit}>
                                <FormTwoInputs>
                                    <div className='flex flex-col justify-self-center gap-6  pt-2 p-4 ' >                                        
                                        <NumberInputInUnits  onChange={(e) => onChange(e)} value={data} id={"data"} type={'number'}
                                                                        group={"data"} label={"data"} name={"data"}
                                                                        dataTitle={t("translation:Data")}/>
                                        <button className=" text-lg text-white p-3 rounded-md bg-indigo-500  hover:bg-indigo-600">Add Data</button>
                                    </div>
                                </FormTwoInputs>
                            </form>
                        </div>
                    </div>
                    </>)}
            </div>
        </div>
      </>
  )
}

export default DataChart