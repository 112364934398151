import React, {useState} from 'react'
import {FaSignInAlt} from 'react-icons/fa'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useSelector, useDispatch} from 'react-redux'
import {signin} from '../../../features/auth/authSlice'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import FormCard from 'UI/components/shared/FormCard'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import {SetAxiosAuthHeader} from "../../../App";
import {UserData} from "@asirisos/types/data";


function Login() {
    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        login: '',
        password: ''
    })

    const {login, password} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const {isLoading} = useSelector((state: RootState) => state.auth)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const userData: UserData = {
            name: "",
            email: login,
            password
        }

        dispatch(signin(userData))
            .unwrap()
            .then(() => {
                SetAxiosAuthHeader();
                navigate("/home")
            })
            .catch(() => {
                toast.error(t("errors:invalidCredentials"))
            })
    }

    if (isLoading) {
        return <Spinner/>
    }
    return (
        <>
            <div className=' flex justify-center max-sm:pt-10 pt-28 max-sm:bg-gray-100 max-sm:h-screen'>
                <FormCard title='Login'>
                    <section className="heading">
                        <div className="flex justify-center">
                            <h1 className="pt-2 px-4"><FaSignInAlt/></h1>
                            <h1> LogIn </h1>
                        </div>
                    </section>
                    <form onSubmit={onSubmit}>
                        <div className='flex justify-center'>
                            <div className='max-w-2xl'>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={login} id={"login"}
                                                    type={'text'} group={"login"} label={"login"} name={"login"}
                                                    dataTitle="Login"/>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={password} id={"password"}
                                                    type={'password'} group={"password"} label={"password"}
                                                    name={"password"} dataTitle="Password"/>
                                {/* <LegacyDropDownInput onChange={(e) => onChange(e)} selections={roleSelection} value={role} id={"role"} type={'text'} group={"role"} label={"role"} name={"role"} dataTitle="role" /> */}
                            </div>
                        </div>
                        <div className="form-group pt-14">
                            <button className="btn btn-block">
                                LogIn
                            </button>
                        </div>

                        <div className='pt-5'>
                        <h1 className='max-sm:py-1 max-sm:text-lg max-sm:font-light max-sm:text-center text-start font-light text-xl pt-1 text-black'>
                            <a className="font-semibold border-indigo-500 text-indigo-500 hover:underline hover:font-extrabold "> <Link className='' to={"/register"}>Register</Link></a> to try beta yourself <br/>
                            <a className="font-semibold border-indigo-500 text-indigo-500 hover:underline hover:font-extrabold "><Link to={"/sign-up-form"}> Sign-up</Link></a> and we will reach out to you</h1>
                        </div>
                        {/* <Link to='/forgotpassword' className='btn btn-reverse btn-block'>
                            Forgot Password
                        </Link> */}
                        {/* <div className='flex flex-row justify-center pt-8 p-2' >
                <button className="btn btn-wide btn-primar hover:bg-black">Register</button>
                <Link to='/forgotpassword' className='btn btn-reverse btn-block' >
                    Forgot Password 
                </Link>
            </div> */}

                    </form>
                </FormCard>
            </div>

            {/*
        <section className="heading">
            <div className="flex justify-center" >
                <h1 className=" pt-2 px-6"><FaSignInAlt/> </h1>
                <h1> Login </h1>
            </div>
            <p className="text-50xl" >Please create an account</p>
        </section>

        <section className="form">
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <input 
                    type="login" 
                    className="form-control"
                    id='login'
                    name='login'
                    value={login}
                    onChange={onChange}
                    placeholder='Enter your login'
                    required />
                </div>
                <div className="form-group">
                    <input 
                    type="password" 
                    className="form-control"
                    id='password'
                    name='password'
                    value={password}
                    onChange={onChange}
                    placeholder='Enter your password'
                    required />
                </div>
                <div className="form-group">
                    <button className="btn btn-block">
                        Submit
                    </button>
                </div>
                <Link to='/forgotpassword' className='btn btn-reverse btn-block' >
                    Forgot Password 
                </Link>
            </form>
        </section> */}
        </>
    )
}

export default Login